import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";

import CommonClasses from "Styles/Common.module.css";
function Tutor() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        Services.getCategories("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Categories);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories();
        // eslint-disable-next-line
    }, []);
    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/categories/update?id=${id}`);
    };
    
    return (
        <>
            {loading ? (
                <SkeletonLoad
                    TableHead={["Category Image", "Name", "Created", "Status"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Category Image</th>
                                <th>Name</th>
                                <th>Created </th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr
                                    id={item.CategoryID}
                                    name={item.CategoryName}>
                                    <td>
                                        {" "}
                                        <img
                                            style={{ width: "6rem" }}
                                            src={item.CategoryImage}
                                            alt="category-img"
                                        />
                                    </td>
                                    <td>{item.CategoryName}</td>

                                    <td>
                                        <Moment format="DD-MM-YYYY">
                                            {item.CreatedAt}
                                        </Moment>{" "}
                                    </td>
                                    <td>
                                        {item.Status === 1 ? (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-active"
                                                    ]
                                                }>
                                                <span>Active</span>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-inactive"
                                                    ]
                                                }>
                                                <span>Inactive</span>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                CommonClasses["Action-btns"]
                                            }>
                                            <button
                                                id={item.CategoryID}
                                                onClick={editHandler}>
                                                <FaRegEdit />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Categories Found</p>
                </div>
            )}
        </>
    );
}

export default Tutor;
