
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import ScrollToTop from "./Util/ScrollToTop";
// --------------pages -----------------------------
import Router from './Routes/routes'
import AccessDenied from "./pages/AccessDenied/Popup"
import useStore from "./store";
function App() {
  const { showAccessDenied, setShowAccessDenied } = useStore(item => item)

  return (
    <>
      <AccessDenied showModal={showAccessDenied} setModal={setShowAccessDenied} />
      <ScrollToTop />
      <ToastContainer />
      {/* {localStorage.getItem('token') &&
      <SideNav Active={ActiveTab} setShow={setshowNavbar} show={showNavbar} />
      } */}
      <Router />
    </>
  );
}

export default App;
