export const AccessControl = (Name) => {
    const AccessItems = JSON.parse(localStorage.getItem("AccessItems"))
    if (AccessItems && AccessItems.length > 0) {
   
     let AccessItem = AccessItems.filter(item => item.name === Name)
   
     if (AccessItem.length > 0) {
      return AccessItem[0].Permissions
     }
    }
   
    return { read: 1, write: 1, edit: 1 }
   
   }