import topnavclasses from "./topNav.module.css";
import User from "../../Assets/user.svg";
import { HiOutlineLogout } from "react-icons/hi";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";

// import Notifications from "./Notifications";
import useStore from "../../store";
import { Services } from "../../Services";
import { warningSwal } from "../../Util/Toast";
import { setLocalStorageWithExpiration } from "../../Util/localData";
function TopNav() {
    const Navigate = useNavigate();
    const { NotificationCount, setNotificationCount } = useStore(
        (item) => item
    );
    const userdata = JSON.parse(localStorage.getItem("admindata"));

    const logoutHandler = () => {
        swal("Are You sure you want to Sign out?", {
            icon: "warning",
            className: topnavclasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: topnavclasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Navigate("/");
                    localStorage.clear();
                    break;

                default:
                // swal("Got away safely!");
            }
        });
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const Reset_NotificationCount = () => {
        Services.Reset_Notification_count("POST", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setNotificationCount(0);
                    setLocalStorageWithExpiration("Notification", 0);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    const NotificationHandler = () => {
        Navigate("/Notifications");
        if (NotificationCount > 0) {
            Reset_NotificationCount();
        }
    };
    return (
        <div className={topnavclasses["TopNav"]}>
            <div className={topnavclasses["user"]}>
                <div className={topnavclasses["user-avatar"]}>
                    <img src={User} alt="user" />
                </div>
                <p className={topnavclasses["username"]}>{localStorage.getItem("Username")}</p>
                <button onClick={logoutHandler}>
                    <HiOutlineLogout />
                </button>
            </div>
        </div>
    );
}

export default TopNav;
