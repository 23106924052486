// UpdateSubscriptionForm.js
import React, { useEffect } from 'react';
import UserClasses from '../users/Users.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import AOS from 'aos';
import 'aos/dist/aos.css';

const UpdateSubscriptionForm = ({
  updateFormData,
  handleInputChange,
  handleFormSubmit,
  handleCancelUpdate,
}) => {
  useEffect(() => {
    AOS.init({
        duration: 1000, // Duration of animation
        once: true, // Whether animation should happen only once
        // Add more options as needed
    });
}, []);
  return (
    <div className={UserClasses['my-modal-overlay']}>
      <div className={UserClasses['my-modal-content']} data-aos="flip-left">
        <div className={UserClasses['my-main-close-btn']}>
          <button className={UserClasses['myclose-btn']} onClick={handleCancelUpdate}>
            <AiOutlineClose />
          </button>
        </div>
        <form className={UserClasses['my-form']}>
          {/* Replace the comments with your actual input fields */}
          <label className={UserClasses['my-label']}>
            BASE USERS:
            <input
              type="number"
              name="baseUsers"
              className={UserClasses['my-input']}
              value={updateFormData.baseUsers}
              onChange={handleInputChange}
            />
          </label>
          <label className={UserClasses['my-label']}>
            MONTHLY PRICE:
            <input
              type="number"
              name="monthlyPrice"
              className={UserClasses['my-input']}
              value={updateFormData.monthlyPrice}
              onChange={handleInputChange}
            />
          </label>
          <label className={UserClasses['my-label']}>
            PRICE PER EXTRA USER:
            <input
              type="number"
              name="pricePerExtraUser"
              className={UserClasses['my-input']}
              value={updateFormData.pricePerExtraUser}
              onChange={handleInputChange}
            />
          </label>
          <label className={UserClasses['my-label']}>
            DESCRIPTION:
            <input
              type="text"
              name="description"
              className={UserClasses['my-input']}
              value={updateFormData.description}
              onChange={handleInputChange}
            />
          </label>

          <button
            className={UserClasses['my-button']}
            type="button"
            onClick={handleFormSubmit}
          >
            Update Subscription
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateSubscriptionForm;
