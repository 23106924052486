import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from './Users.module.css';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { IoIosAdd } from "react-icons/io";
import { useNavigate, useParams } from 'react-router-dom';
import defaultImage from 'Assets/square-user.jpg';
import ProductModal from './ProductModal';
import { groupProductsTableHeads, groupUsersTableHeads } from '../constants/Constants';
import { Button } from '@mui/material';
import UsersSkeleton from './UsersSkeleton';
import AOS from 'aos';
import 'aos/dist/aos.css';

const GroupByArea = () => {
  const { groupId } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [groupProducts, setGroupProducts] = useState([]);
  const [groupUser, setGroupUser] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    const fetchData = async () => {
      try {
        setLoading(true);

        if (activeTab === 1) {
          const result = await Services.getGroupUsers(groupId, token);
          setGroupUser(result.groupUser);
        } else if (activeTab === 2) {
          const productResults = await Services.getGroupProducts(groupId, token);
          setGroupProducts(productResults.products);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    if (activeTab) {
      fetchData();
    }
  }, [activeTab, groupId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTableRowClick = (productId) => {
    const productClicked = groupProducts.find((product) => product.productId === productId);

    if (productClicked) {
      setSelectedProduct(productClicked);
      setModalOpen(true);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      once: true, // Whether animation should happen only once
      // Add more options as needed
    });
  }, []);

  return (
    <Layout Active={'Account Settings'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Users</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          <div className={UserClasses['add-users-btn']}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              BACK
            </Button>
          </div>
          <div className={UserClasses['users-sub-one']}>
            <div className={UserClasses['users-sub-two']}>
              <div className={UserClasses['users-div']}>
                <h4 className={UserClasses['users-heading-one']}>Users</h4>
              </div>
              <div className={UserClasses['users-heading-two']}>
                <button
                  className={activeTab === 1 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(1)}
                >
                  Group Users
                </button>
                <button
                  className={activeTab === 2 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(2)}
                >
                  Group Products
                </button>
              </div>

              {activeTab === 1 && (
                <div style={{ overflowY: 'hidden' }}>
                  {loading ? (
                    <UsersSkeleton TableHeader={groupUsersTableHeads} />
                  ) : (
                    <table className={UserClasses['Table']}>
                      <thead>
                        <tr>
                          {groupUsersTableHeads.map((head, index) => (
                            <th key={index} className={UserClasses['users-table-data']}>{head}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {groupUser.length > 0 && groupUser.map((user) => (
                          <tr key={user.groupId}>
                            <td>{user.UID}</td>
                            <td className={UserClasses['img-cont']}>
                              <img
                                src={user.Photo || defaultImage}
                                alt={''}
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '50%',
                                }}
                                onError={(e) => {
                                  e.target.src = defaultImage;
                                }}
                              />
                            </td>
                            <td>{user.name}</td>
                            <td>{user.mobileNumber}</td>
                            <td>{user.email}</td>
                            <td>{user.businessName}</td>
                            <td>{user.GSTNumber}</td>
                          </tr>
                        ))}
                        {!loading && groupUser.length === 0 && <p>No records found</p>}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {activeTab === 2 && (
                <div style={{ overflowY: 'hidden' }}>
                  {loading ? (
                    <UsersSkeleton TableHeader={groupProductsTableHeads} />
                  ) : (
                    <table className={UserClasses['Table']}>
                      <thead>
                        <tr>
                          {groupProductsTableHeads.map((head, index) => (
                            <th key={index} className={UserClasses['users-table-data']}>{head}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {groupProducts.length > 0 && groupProducts.map((product) => (
                          <tr key={product.productId} onClick={() => handleTableRowClick(product.productId)}>
                            <td style={{ position: 'relative' }}>
                              <div className={`${UserClasses['status-tag']}`}>{product.productCategory}</div>
                              <img
                                src={product.productImageList.length > 0 ? product.productImageList[0] : defaultImage}
                                alt={product.productName}
                                style={{ width: '50px', height: '50px' }}
                              />
                            </td>
                            <td>{product.productName}</td>
                            <td>{product.price}</td>
                            <td>{new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: false, // Use 24-hour format
                            }).format(new Date(product.createdAt))}</td>
                          </tr>
                        ))}
                        {!loading && groupProducts.length === 0 && <p>No records found</p>}
                      </tbody>
                    </table>
                  )}
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      {
        isModalOpen && (
          <ProductModal
            product={selectedProduct}
            onClose={() => setModalOpen(false)}
          />
        )
      }
    </Layout >
  );
};

export default GroupByArea;
