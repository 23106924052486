
import Categories from "pages/Categories/index"
import AddCategory from "../pages/Categories/addCategory"

import Login from "../pages/Login/index"
import AccountSettings from "pages/Admin/users/Users"
import EachUser from "pages/Admin/users/EachUser"
import GroupByArea from "pages/Admin/users/GroupByArea"
import AddUser from "pages/Admin/users/adduser/AddUser"
import AllOrders from "pages/Admin/orders/AllOrders"
import SubscriptionPlans from "pages/Admin/subscriptions/SubscriptionPlans"
import AddSubscription from "pages/Admin/subscriptions/AddSubscription"
import EachOrder from "pages/Admin/orders/EachOrder"
import AdminSettings from "pages/Admin/account/AdminSettings"
import TeamSettings from "pages/Admin/account/TeamSettings"
import EachUserOrder from "pages/Admin/users/EachUserOrder"
import CreatedOrder from "pages/Admin/users/CreatedOrder"
import CreateTeamMember from "pages/Admin/account/CreateTeamMember"
import BillDetails from "pages/Admin/users/BillDetails"
import Gaurd from "pages/Admin/Util/Guard"
import { AccessControl } from "pages/Admin/Util/AccessControl"
import Complaints from "pages/Admin/support/Complaints"
import Configuration from "pages/Admin/configuration/Configuration"
import CreateNewConfig from "pages/Admin/configuration/CreateNewConfig"
import EditConfig from "pages/Admin/configuration/EditConfig"
import TicketDetails from "pages/Admin/support/TicketDetails"
import Dashboard from "pages/Admin/dashboard/Dashboard"
import ProductPrice from "pages/Admin/product/ProductPrice"
import ProductCategory from "pages/Admin/product/ProductCategory"
import UsersReferenceGuide from "pages/Admin/userreference/UsersReferenceGuide"
import AddUsersReferenceGuide from "pages/Admin/userreference/AddUsersReferenceGuide"
import EditUsersReferenceGuide from "pages/Admin/userreference/EditUsersReferenceGuide"
import ProductSubCategory from "pages/Admin/product/ProductSubCategory"
export const AccessKeys = {
    AccountSettings: 'AccountSettings',
    Users: 'Users',
    SubscriptionPlans: 'Subscription Plans',
    AdminSettings: 'Admin Settings',
    Permissions: 'Permissions',
    Complaints: 'Complaints',
    Configuration: 'Configuration',
    ProductPrice: 'Product Price',
    ProductCategory: 'Product Category',
    UsersReferenceGuide: 'Users Reference Guide'
}


export const PageRoutes = [


 { path: '/', element: <Login /> },
 { path: '/categories', element: <Categories /> },
 { path: '/categories/:category', element: <AddCategory /> },
 { path: '/account-settings', element: <AccountSettings />, AccessKey: AccessKeys.Users, Type: 'read' },
 { path: '/each-user/:userId', element: <EachUser /> },
 { path: '/group-by-area/:groupId', element: <GroupByArea /> },
 { path: '/add-user', element: <AddUser />},
 { path: '/orders', element: <AllOrders /> },
 { path: '/productprice', element: <ProductPrice /> },
 { path: '/productcategory', element: <ProductCategory /> },
 { path: '/productsubcategory', element: <ProductSubCategory /> },
 { path: '/subscriptionplans', element: <SubscriptionPlans /> },
 { path: '/addsubscription', element: <AddSubscription /> },
 { path: '/each-order/:orderId', element: <EachOrder /> },
 { path: '/adminsettings', element: <AdminSettings />},
 { path: '/permissions', element: <TeamSettings />},
 { path: '/each-user-order/:orderId', element: <EachUserOrder /> },
 { path: '/created-order/:orderId', element: <CreatedOrder /> },
 { path: '/create-team-member', element: <CreateTeamMember /> },
 { path: '/bill-details/:monthlyPaymentId', element: <BillDetails /> },
 { path: '/guard', element: <Gaurd /> },
 { path: '/accesscontrol', element: <AccessControl /> },
 { path: '/complaints', element: <Complaints /> },
 { path: '/configuration', element: <Configuration />},
 { path: '/createnewconfig', element: <CreateNewConfig />},
 { path: '/editconfig/:configId/:configKey/:configValue', element: <EditConfig /> },
 { path: '/ticket-details/:userId', element: <TicketDetails /> },
 { path: '/dashboard', element: <Dashboard /> },
 { path: '/usersreferenceguide', element: <UsersReferenceGuide /> },
 { path: '/addusersreferenceguide', element: <AddUsersReferenceGuide /> },
 { path: '/editusersreferenceguide/:userGuideId', element: <EditUsersReferenceGuide /> },

]
