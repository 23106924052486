import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { IoIosAdd } from "react-icons/io";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { allOrderTableHeads } from '../constants/Constants';
import UsersSkeleton from '../users/UsersSkeleton';
import { useSearchParams } from 'react-router-dom';

const ITEMS_PER_PAGE = 10;

const AllOrders = () => {
  const [searchparams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const urlPage = new URLSearchParams(location.search).get('page') || 1;
  const [currentPage, setCurrentPage] = useState(parseInt(urlPage, 10));

  const navigate = useNavigate();

  useEffect(() => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    const fetchData = async () => {
      try {
        setLoading(true);
        const urlActiveTab = searchparams.get('activeTab');
        const urlPage = searchparams.get('page') || 1;

        setActiveTab(parseInt(urlActiveTab, 10));
        setCurrentPage(parseInt(urlPage, 10));

        const result = await Services.getOrdersBySupplierOrdersStatus(urlActiveTab, token);

        if (result.isActive === 0) {
          throw new Error('Error fetching data. Please try again');
        }

        setData(result.orders);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200)
      }
    };

    if (activeTab) {
      fetchData();
    }
  }, [activeTab, location.search, currentPage]);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    navigate(`${location.pathname}?page=1&activeTab=${tab}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Update the URL with the new page and active tab
    navigate(`${location.pathname}?page=${pageNumber}&activeTab=${activeTab}`);
  };


  // const handleTableRowClick = (userId) => {
  //   // Redirect to a new page with the selected user's ID
  //   // You can replace '/user-details' with the actual path you want
  //   // and pass the user ID as a parameter
  //   return <NavLink to={`/each-user/${userId}`} />;
  // };

  return (
    <Layout Active={'Orders'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orders</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          <div className={UserClasses['users-sub-one']}>
            <div className={UserClasses['users-sub-two']}>
              <div className={UserClasses['users-div']}>
                <h4 className={UserClasses['users-heading-one']}>Orders</h4>
              </div>
              <div className={UserClasses['users-heading-two']}>
                <button
                  className={activeTab === 1 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(1)}
                >
                  New
                </button>
                <button
                  className={activeTab === 2 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(2)}
                >
                  Processing
                </button>
                <button
                  className={activeTab === 3 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(3)}
                >
                  Invoice Printed
                </button>
                <button
                  className={activeTab === 4 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(4)}
                >
                  Transport
                </button>
                <button
                  className={activeTab === 5 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(5)}
                >
                  Delivered
                </button>
                <button
                  className={activeTab === 6 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(6)}
                >
                  Rejected
                </button>
              </div>

              <div>
                <SkeletonTheme color="#ddd" highlightColor="#eee" >
                  {loading ? (
                    <UsersSkeleton TableHeader={allOrderTableHeads} />
                  ) : (
                    <div style={{ overflowY: 'hidden' }}>
                      {data.length > 0 ? (
                        <div>
                          <table className={UserClasses['Table']}>
                            <thead>
                              <tr>
                                {allOrderTableHeads.map((head, index) => (
                                  <th key={index} className={UserClasses['users-table-data']}>{head}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.slice(startIndex, endIndex).map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-order/${item.orderId}`}>
                                      {item.orderId}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-order/${item.orderId}`}>
                                      {item.userName.length > 10 ? `${item.userName.slice(0, 7)}...` : item.userName}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink className={`${UserClasses['navlink']} ${UserClasses['styled-date']}`} to={`/each-order/${item.orderId}`}>
                                      {new Intl.DateTimeFormat('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        // hour: 'numeric',
                                        // minute: 'numeric',
                                        // second: 'numeric',
                                        // hour12: false, 
                                      }).format(new Date(item.createdAt))}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-order/${item.orderId}`}>
                                      {Math.round(item.pendingPayment)}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-order/${item.orderId}`}>
                                      {Math.round(item.receivedPayement)}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-order/${item.orderId}`}>
                                      {Math.round(item.totalPrice)}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-order/${item.orderId}`}>
                                      {item.rejectReason}
                                    </NavLink>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className={UserClasses['pagination-container']}>
                            {currentPage > 1 && (
                              <button
                                className={UserClasses['pagination-btn']}
                                onClick={() => handlePageChange(currentPage - 1)}
                              >
                                &#8592; {/* Left arrow */}
                              </button>
                            )}

                            {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => (
                              pageNumber > 0 && pageNumber <= Math.ceil(data.length / ITEMS_PER_PAGE) && (
                                <button
                                  key={pageNumber}
                                  className={currentPage === pageNumber ? UserClasses.activePage : UserClasses.page}
                                  onClick={() => handlePageChange(pageNumber)}
                                >
                                  {pageNumber}
                                </button>
                              )
                            ))}

                            {currentPage < Math.ceil(data.length / ITEMS_PER_PAGE) && (
                              <button
                                className={UserClasses['pagination-btn']}
                                onClick={() => handlePageChange(currentPage + 1)}
                              >
                                &#8594; {/* Right arrow */}
                              </button>
                            )}
                          </div>
                        </div>

                      ) : (
                        <table className={UserClasses['Table']}>
                          <thead>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                No Records Found
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </SkeletonTheme>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllOrders;
