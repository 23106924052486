import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import BoxImg from '../../../Assets/box-img.png';
import { IoPerson } from 'react-icons/io5';
import { IoLocationSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { FaRupeeSign } from "react-icons/fa";
import orderbox from '../../../Assets/box-img.png'
import { Button } from '@mui/material';
import { CgBorderBottom } from "react-icons/cg";
import { SlideshowLightbox } from 'lightbox.js-react';
import noproductimage from '../../../Assets/no-product2.jpg'
import { BiSolidBusiness, BiSolidUserCircle } from 'react-icons/bi';
import { VscTypeHierarchySub } from 'react-icons/vsc';
import LoadingSpinnerComponent from 'react-spinners-components'; // Add this line
import newboxesordered from '../../../Assets/boxes-order.gif'
import { previousPendingPaymentsTableHeads } from '../constants/Constants';
import { SiGooglesheets, SiTaxbuzz } from "react-icons/si";
import { MdBookOnline, MdCarCrash, MdDateRange, MdOutlineMergeType, MdPerson, MdPrecisionManufacturing, MdStickyNote2 } from 'react-icons/md';
import { PiTextStrikethroughDuotone } from "react-icons/pi";
import { GiConfirmed, GiDorsalScales, GiMoneyStack, GiNotebook, GiProfit, GiReceiveMoney } from "react-icons/gi";
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { TbBrandDaysCounter, TbLayersDifference } from "react-icons/tb";
import { GrStatusCriticalSmall, GrUserWorker } from 'react-icons/gr';
import { MdDoNotDisturbOnTotalSilence } from "react-icons/md";
import { IoIosPricetags } from "react-icons/io";
import BoardClasses from '../dashboard/Dashboard.module.css'
import { BsPersonWorkspace } from 'react-icons/bs';
import { FaPersonCircleCheck, FaScaleUnbalancedFlip } from 'react-icons/fa6';
import { FcMoneyTransfer } from 'react-icons/fc';


const CreatedOrder = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderedBy, setOrderedBy] = useState({})
  const [orderedFrom, setOrderedFrom] = useState({})
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [previousPendingPayments, setPreviousPendingPayments] = useState(' ');


  const navigate = useNavigate()

  const { orderId } = useParams();

  useEffect(() => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await Services.getOrderDetails(orderId, token);

        if (result.Status !== 1) {
          throw new Error('Error fetching data. Please try again');
        }

        setData(result.orderDetails);
        setOrderedBy(result.orderedBy)
        setOrderedFrom(result.orderedFrom)
        setPreviousPendingPayments(result.previousPendingPayments)
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    fetchData();
  }, [orderId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getStatusLabel = (statusNumber) => {
    switch (statusNumber) {
      case 1:
        return 'New';
      case 2:
        return 'Processing';
      case 3:
        return 'Packed';
      case 4:
        return 'In Transit';
      case 5:
        return 'Delivered';
      case 6:
        return 'Rejected';
      default:
        return 'Unknown Status';
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setSelectedImage('');
    setLightboxOpen(false);
  };

  const InvoiceBill = () => {
    window.open(data.invoice, "_blank");
  };

  return (
    <Layout Active={'Account Settings'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Users</title>
      </Helmet>

      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          <div className={UserClasses['add-users-btn']}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              BACK
            </Button>
          </div>
          <div className={UserClasses['users-sub-one']}>
              <div className={UserClasses['users-sub-two']}>
                <div className={UserClasses['order-info-main']}>
                  <div>
                    <p>OrderId - {data.orderId}</p>
                  </div>
                </div>
                <div className={UserClasses['users-div-main']}>
                  <div>
                    <img src={orderbox} className={UserClasses['left-side-image-main']} alt="Box Image" />
                  </div>
                  <div className={UserClasses['content-container']}>
                    <h4 className={UserClasses['each-heading-one']}>{getStatusLabel(data.suplierOrdersStatus)}</h4>
                    <h4 className={`${UserClasses['navlink']} ${UserClasses['styled-date']}`}>
                      {data.createdAt ? (
                        new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: false, // Use 24-hour format
                        }).format(new Date(data.createdAt))
                      ) : (
                        "No date available" // Or any other appropriate message
                      )}
                    </h4>
                  </div>

                </div>
              </div>
              <div className={UserClasses['users-two']}>
                {data ? (
                  <div>
                    {data && (
                      <div style={{ margin: '0px' }} className={BoardClasses['users-secondary-main']}>
                        <div style={{ border: '1px solid gray', margin: '0px' }} className={BoardClasses['sec-card-one']}>
                          <div className={BoardClasses['inside-sec-one-card']}>
                            <h3><MdPrecisionManufacturing /></h3>
                          </div>
                          <div className={BoardClasses['inside-sec-one-two-card']}>
                            <p style={{ fontSize: '10px' }} className={BoardClasses['text-sec-card']}>Manufacturer to Agent Commission</p>
                            <div className={BoardClasses['sub-head-data']}>
                              <p><FaRupeeSign style={{ color: 'green', marginTop: '5px', fontSize: '20px' }} className={UserClasses['io-rupee']} /></p>
                              <p style={{ fontSize: '24px', fontWeight: '600' }}>{data.millerCommission}</p>
                            </div>
                          </div>
                        </div>
                        <div style={{ border: '1px solid gray', margin: '0px' }} className={BoardClasses['sec-card-one']}>
                          <div className={BoardClasses['inside-sec-one-card']}>
                            <h3><MdOutlineEmojiTransportation /></h3>
                          </div>
                          <div className={BoardClasses['inside-sec-one-two-card']}>
                            <p style={{ fontSize: '10px' }} className={BoardClasses['text-sec-card']}>Transport to Agent Commission</p>
                            <div className={BoardClasses['sub-head-data']}>
                              <p><FaRupeeSign style={{ color: 'green', marginTop: '5px', fontSize: '20px' }} className={UserClasses['io-rupee']} /></p>
                              <p style={{ fontSize: '24px', fontWeight: '600' }}>{data.transportCommission}</p>
                            </div>
                          </div>
                        </div>
                        <div style={{ border: '1px solid gray', margin: '0px' }} className={BoardClasses['sec-card-one']}>
                          <div className={BoardClasses['inside-sec-one-card']}>
                            <h3><BsPersonWorkspace /></h3>
                          </div>
                          <div className={BoardClasses['inside-sec-one-two-card']}>
                            <p style={{ fontSize: '10px' }} className={BoardClasses['text-sec-card']}>Distributor to Agent Commission</p>
                            <div className={BoardClasses['sub-head-data']}>
                              <p><FaRupeeSign style={{ color: 'green', marginTop: '5px', fontSize: '20px' }} className={UserClasses['io-rupee']} /></p>
                              <p style={{ fontSize: '24px', fontWeight: '600' }}>{data.distributorCommission}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <p>No records found</p>
                )}
              </div>
              <div className={UserClasses['users-two']}>
                <SkeletonTheme color="#ddd" highlightColor="#eee">
                  {loading ? (
                    <div>
                      <Skeleton className={UserClasses['order-skel']} />
                      <Skeleton className={UserClasses['order-skel']} />
                      <Skeleton className={UserClasses['order-skel']} />
                      <Skeleton className={UserClasses['order-skel']} />
                      <Skeleton className={UserClasses['order-skel']} />
                      <Skeleton className={UserClasses['order-skel']} />
                    </div>
                  ) : (
                    <div style={{ overflowY: 'hidden' }}>
                      <div className={UserClasses['users-div']}>
                        <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Previous Pending Payments</h4>
                      </div>
                      <div>
                        <div style={{ overflowY: 'hidden' }}>
                          <table className={UserClasses['Table']}>
                            <thead>
                              <tr>
                                {previousPendingPaymentsTableHeads.map((head, index) => (
                                  <th key={index} className='users-table-data'>{head}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan={previousPendingPaymentsTableHeads.length}>
                                    <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                                  </td>
                                </tr>
                              ) : previousPendingPayments.length > 0 ? (
                                previousPendingPayments.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.userId}</td>
                                    <td>{item.userName}</td>
                                    <td>{item.invoiceNumber}</td>
                                    <td>{item.paymentStatus === 0 ? 'Pending' : 'Completed'}</td>
                                    <td>{Number(item.pendingPayment).toFixed(2)}</td>
                                    <td>{Number(item.receivedPayement).toFixed(2)}</td>
                                    <td>{Number(item.totalPrice).toFixed(2)}</td>
                                    <td>{Number(item.finalAmount).toFixed(2)}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={previousPendingPaymentsTableHeads.length}>
                                    No Previous Pending Payments Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {data ? (
                        <div>
                          {orderedBy && (
                            <div className={UserClasses['user-orderfrom']}>
                              <div className={UserClasses['users-div']}>
                                <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Order By</h4>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><IoPerson style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p className={UserClasses['record-numbs']}>User Name: {orderedBy.name}</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><BiSolidBusiness style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p className={UserClasses['record-numbs']}>Business Name: {orderedBy.businessName}</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><IoIosCall style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p className={UserClasses['record-numbs']}>Mobile Number: {orderedBy.mobileNumber}</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><IoLocationSharp style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p className={UserClasses['record-numbs']}>Address: {orderedBy.address}</p>
                              </div>
                            </div>
                          )}


                          <div className={UserClasses['user-orderfrom']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Order From</h4>
                            </div>
                            {orderedFrom && ( // Check if orderedFrom is defined
                              <div>
                                <div className={UserClasses['delivery-data']}>
                                  <p><IoPerson style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                  <p className={UserClasses['record-numbs']}>User Name: {orderedFrom.name}</p>
                                </div>
                                <div className={UserClasses['delivery-data']}>
                                  <p><BiSolidBusiness style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                  <p className={UserClasses['record-numbs']}>Business Name: {orderedFrom.businessName}</p>
                                </div>
                                <div className={UserClasses['delivery-data']}>
                                  <p><IoIosCall style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                  <p className={UserClasses['record-numbs']}>Mobile Number: {orderedFrom.mobileNumber}</p>
                                </div>
                                <div className={UserClasses['delivery-data']}>
                                  <p><IoLocationSharp style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                  <p className={UserClasses['record-numbs']}>Address: {orderedFrom.address}</p>
                                </div>
                              </div>
                            )}
                          </div>


                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Delivery Address</h4>
                            </div>
                            <div className={UserClasses['delivery-data']}>
                              <p><IoPerson style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                              <p className={UserClasses['record-numbs']}>Name: {data.userName}</p>
                            </div>
                            <div className={UserClasses['delivery-data']}>
                              <p><IoLocationSharp style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                              <p className={UserClasses['record-numbs']}>Address: {data.deliveryAddress}</p>
                            </div>
                            <div className={UserClasses['delivery-data']}>
                              <p><IoIosCall style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                              <p className={UserClasses['record-numbs']}>Mobile Number: {data.recieverMobileNumber}</p>
                            </div>
                            <div className={UserClasses['delivery-data']}>
                              <p><SiTaxbuzz style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                              <p className={UserClasses['record-numbs']}>GST: {data.GSTNumber}</p>
                            </div>
                          </div>

                          <div>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Package Items</h4>
                            </div>
                            {data && data.products && data.products.length > 0 ? (
                              data.products.map((product, index) => (
                                <div className={UserClasses['prod-main']} key={index}>
                                  <div className={UserClasses['prod-div']}>
                                    <div>
                                      <SlideshowLightbox
                                        isOpen={lightboxOpen}
                                        onClose={handleCloseLightbox}
                                        startIndex={0} // Index of the selected image, if you have a gallery
                                      >
                                        {product.productImageList && product.productImageList.length > 0 ? (
                                          product.productImageList.map((image, imgIndex) => (
                                            <img
                                              key={imgIndex}
                                              className={UserClasses['left-side-image']}
                                              src={image || noproductimage}
                                              alt=""
                                              onClick={() => handleImageClick(image)}
                                              onError={(e) => {
                                                e.target.src = noproductimage;
                                              }}
                                            />
                                          ))
                                        ) : (
                                          <img
                                            className={UserClasses['left-side-image']}
                                            src={noproductimage}
                                            alt="No product image"
                                            onError={(e) => {
                                              e.target.src = noproductimage;
                                            }}
                                          />
                                        )}
                                      </SlideshowLightbox>
                                    </div>
                                    <div className={UserClasses['content-container']}>
                                      <div>
                                        <h4 className={UserClasses['eachorder-heading-one']}>{product.productName}</h4>
                                        <h4 className={UserClasses['eachorder-heading-one']}>{product.productSubCategory}</h4>
                                        <h4 className={UserClasses['eachorder-heading-one']}>Qty: {product.qty} Bags</h4>
                                        <h4 className={UserClasses['eachorder-heading-one']}>Unit: {product.productMeasuringUnit}</h4>
                                      </div>
                                      <div>
                                        <h4 className={UserClasses['eachorder-disc-one']}>{product.cashDiscount}% OFF</h4>
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    product?.productPriceUnit === "Quintal" ?
                                      <div className={UserClasses['prod-sec-div']}>
                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>M Bag Price</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{Number(product.bagPrice).toFixed(2)}/Bag</p>
                                          </div>
                                        </div>
                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>M Price</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{Number(product.price).toFixed(2)}/Quintal</p>
                                          </div>
                                        </div>
                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>A Bag Price</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{Number(product.bagDiscountPrice).toFixed(2)}/Bag</p>
                                          </div>
                                        </div>
                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>A Price</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{Number(product.discountedProductPrice).toFixed(2)}/Quintal</p>
                                          </div>
                                        </div>
                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>{`${product.packingBag.bagName} price`}</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{product.packingBag.price}/Quintal</p>
                                          </div>
                                        </div>
                                      </div> :
                                      <div className={UserClasses['prod-sec-div']}>
                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>M Price</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{product.price}</p>
                                          </div>
                                        </div>

                                        <div className={UserClasses['prod-side-new-sec']}>
                                          <p className={UserClasses['prod-side-new-para']}>A Price</p>
                                          <div className={UserClasses['delivery-data']}>
                                            <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                            <p className={UserClasses['price-of-prod']}>{Number(product.discountedProductPrice).toFixed(2)}</p>
                                          </div>
                                        </div>

                                      </div>
                                  }

                                </div>
                              ))
                            ) : (
                              <p>No Package Items found</p>
                            )}
                          </div>
                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><FaScaleUnbalancedFlip style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Total Quintals</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p className={UserClasses['price-of-prod']}><p>{Number(data.totalWeightInQuintal).toFixed(2)}</p></p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GiDorsalScales style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Total Tons</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p className={UserClasses['price-of-prod']}><p>{Number(data.totalWeightInTons).toFixed(2)}</p></p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdDoNotDisturbOnTotalSilence style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Sub Total</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p className={UserClasses['price-of-prod']}><p>{Number(data.totalPrice).toFixed(2)}</p></p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><IoIosPricetags style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Order Price</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green' }} className={UserClasses['io-rupee']} /></p>
                                <p className={UserClasses['price-of-prod']}><p>{Number(data.finalAmount).toFixed(2)}</p></p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GrStatusCriticalSmall style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Payment Status</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p className={UserClasses['price-of-prod']}><p>{data.paymentStatus === 0 ? 'Unpaid' : 'Paid'}</p></p>
                              </div>
                            </div>
                          </div>

                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['record-numbs']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><SiGooglesheets style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Manufacturer(Miller) Record Number</p>
                              </div>
                              <p>{data.millerRecordNumber}</p>
                            </div>
                            <div className={UserClasses['record-numbs']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><SiGooglesheets style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Agent Record Number</p>
                              </div>
                              <p>{data.agentRecordNumber}</p>
                            </div>
                            <div className={UserClasses['record-numbs']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdOutlineMergeType style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Order Type</p>
                              </div>
                              <p>{data.orderType ? data.orderType.orderType : 'NA'}</p>
                            </div>
                            <div className={UserClasses['record-numbs']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><VscTypeHierarchySub style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Delivery Type</p>
                              </div>
                              <p>{data.deliveryType ? data.deliveryType.deliveryType : 'NA'}</p>
                            </div>
                            <div className={UserClasses['record-numbs']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdDateRange style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Date</p>
                              </div>
                              <p>{formatDate(data.createdAt)}</p>
                            </div>
                          </div>

                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Transportation Details</h4>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><FaPersonCircleCheck style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Name</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p>{data.transportDetails ? data.transportDetails.name : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdCarCrash style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Vehicle Number</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p>{data.transportDetails ? data.transportDetails.vehicleNumber : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><BiSolidUserCircle style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Driver Number</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p>{data.transportDetails ? data.transportDetails.driverNumber : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdBookOnline style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Booking Rate(LF)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{data.transportDetails ? data.transportDetails.preBooking : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><TbLayersDifference style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Difference Rate(LF)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{data.transportDetails ? data.transportDetails.differenceBooking : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GiConfirmed style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Confirmation Rate(LF)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{data.transportDetails ? data.transportDetails.postBooking : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GiReceiveMoney style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Advance(LF)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{data.transportDetails ? data.transportDetails.advance : 'NA'}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GiMoneyStack style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Balance(LF)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{data.transportDetails ? data.transportDetails.balance : 'NA'}</p>
                              </div>
                            </div>
                          </div>

                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Other Details</h4>
                            </div>
                            <div className={UserClasses['record-numbs']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><PiTextStrikethroughDuotone style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Order Through</p>
                              </div>
                              <p>{data.orderThrough ? data.orderThrough.orderThrough : 'NA'}</p>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdPrecisionManufacturing style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Miller Commission</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{Number(data.millerCommissionPerQuintal).toFixed(2)}/Quintal</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><BsPersonWorkspace style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Distributor Commission</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{Number(data.distributionCommissionPerQuintal).toFixed(2)}/Quintal</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><MdOutlineEmojiTransportation style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Transport Commission</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{Number(data.transportCommission).toFixed(2)}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><TbBrandDaysCounter style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Cash Discount Days(CD/CC)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p>{data.cashDiscountDays}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GrUserWorker style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Hamali Charges(Quintal)</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px' }} className={UserClasses['io-rupee']} /></p>
                                <p>{data.hamaliCharges}</p>
                              </div>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GiNotebook style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Notes</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p>{data.notes}</p>
                              </div>
                            </div>
                          </div>
                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Miller & Broker Notes</h4>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><GiNotebook style={{ color: 'green' }} className={UserClasses['io-person']} /></p>
                                <p>Notes</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p>{data.millerNotes}</p>
                              </div>
                            </div>
                          </div>
                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Payments</h4>
                            </div>
                            <div className={UserClasses['order-price-total']}>
                              <h4 className={UserClasses['pay-money']}>Payment Date</h4>
                              <h4 className={UserClasses['pay-money']}>Payment Recieved</h4>
                            </div>
                            {data.payments && data.payments.length > 0 ? (
                              <div className={UserClasses['payments-container']}>
                                {data.payments.map((payment) => (
                                  <div key={payment.paymentId} className={UserClasses['payment-item']}>
                                    <div className={UserClasses['payment-date']}>
                                      {formatDate(payment.paymentDate)}
                                    </div>
                                    <div className={UserClasses['payment-date']}>
                                      {payment.name}
                                    </div>
                                    <div className={UserClasses['payment-date']}>
                                      {payment.paymentType}
                                    </div>
                                    <div className={UserClasses['delivery-data']}>
                                      <p><FaRupeeSign className={UserClasses['io-last']} /></p>
                                      <p className={UserClasses['price-of-prod']}>{payment.receivedPayement}/-</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p>No payment records found</p>
                            )}
                          </div>
                          <div className={UserClasses['user-prod-style']}>
                              <div className={UserClasses['users-div']}>
                                <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Quality Difference Products</h4>
                              </div>
                              {data && data.Effectedproducts && data.Effectedproducts.length > 0 ? (
                                data.Effectedproducts.map((product, index) => (
                                  <div className={UserClasses['prod-main']} key={index}>
                                    <div className={UserClasses['prod-div']}>
                                      <div>
                                        <SlideshowLightbox
                                          isOpen={lightboxOpen}
                                          onClose={handleCloseLightbox}
                                          startIndex={0} // Index of the selected image, if you have a gallery
                                        >
                                          {product.productImageList && product.productImageList.length > 0 ? (
                                            product.productImageList.map((image, imgIndex) => (
                                              <img
                                                key={imgIndex}
                                                className={UserClasses['left-side-image']}
                                                src={image || noproductimage}
                                                alt=""
                                                onClick={() => handleImageClick(image)}
                                                onError={(e) => {
                                                  e.target.src = noproductimage;
                                                }}
                                              />
                                            ))
                                          ) : (
                                            <img
                                              className={UserClasses['left-side-image']}
                                              src={noproductimage}
                                              alt="No product image"
                                              onError={(e) => {
                                                e.target.src = noproductimage;
                                              }}
                                            />
                                          )}
                                        </SlideshowLightbox>
                                      </div>
                                      <div className={UserClasses['content-container']}>
                                        <div>
                                          <h4 className={UserClasses['eachorder-heading-one']}>{product.productName}</h4>
                                          <h4 className={UserClasses['eachorder-heading-one']}>{product.productSubCategory}</h4>
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      product ?
                                        <div className={UserClasses['prod-sec-div']}>
                                          <div className={UserClasses['prod-side-new-sec']}>
                                            <p className={UserClasses['prod-side-new-para']}>Effected Price</p>
                                            <div className={UserClasses['delivery-data']}>
                                              <p className={UserClasses['price-of-prod']}>{product.effectedPrice || "NA"}</p>
                                            </div>
                                          </div>
                                          <div className={UserClasses['prod-side-new-sec']}>
                                            <p className={UserClasses['prod-side-new-para']}>Effected Quantity</p>
                                            <div className={UserClasses['delivery-data']}>
                                              <p className={UserClasses['price-of-prod']}>{product.effectedQuantity}</p>
                                            </div>
                                          </div>
                                        </div> :
                                        <div className={UserClasses['prod-sec-div']}>
                                          <div className={UserClasses['prod-side-new-sec']}>
                                            <p className={UserClasses['prod-side-new-para']}>Effected Price</p>
                                            <div className={UserClasses['delivery-data']}>
                                              <p className={UserClasses['price-of-prod']}>{product.effectedPrice || "NA"}</p>
                                            </div>
                                          </div>
                                          <div className={UserClasses['prod-side-new-sec']}>
                                            <p className={UserClasses['prod-side-new-para']}>Effected Quantity</p>
                                            <div className={UserClasses['delivery-data']}>
                                              <p className={UserClasses['price-of-prod']}>{product.effectedQuantity}</p>
                                            </div>
                                          </div>
                                        </div>
                                    }
                                  </div>
                                ))
                              ) : (
                                <p>No Quality Difference Products found</p>
                              )}
                          </div>
                          <div className={UserClasses['user-prod-style']}>
                            <div className={UserClasses['users-div']}>
                              <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Final Price</h4>
                            </div>
                            <div className={UserClasses['prod-side-new-sec']}>
                              <div className={UserClasses['main-record-numbers']}>
                                <p><FcMoneyTransfer className={UserClasses['io-person']} /></p>
                                <p>Final Price</p>
                              </div>
                              <div className={UserClasses['delivery-data']}>
                                <p><FaRupeeSign style={{ color: 'green', marginTop: '5px', fontSize: "15px" }} className={UserClasses['io-rupee']} /></p>
                                <p style={{ color: 'gold', fontSize: "20px" }}>{data.finalAmount ? data.finalAmount : 'NA'}</p>
                              </div>
                            </div>
                          </div>
                          {data.invoice !== 'NA' || null || '' ? (
                            <div style={{ marginBottom: '10px', marginTop: '10px' }} className={UserClasses['inside-order-price']}>
                              <div className={UserClasses['users-div']}>
                                <div className={UserClasses['invoice-main-new']}>
                                  <h4 style={{ borderBottom: 'none' }} className={UserClasses['users-heading-one']}>Download Invoice</h4>
                                  <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '5px', borderRadius: '4px', cursor: 'pointer' }} type='button' onClick={() => InvoiceBill()}>Download</button>
                                </div>
                              </div>
                            </div>
                          ) : 'No Invoice Found'}
                        </div>
                      ) : (
                        <p>No records found</p>
                      )}
                    </div>
                  )}
                </SkeletonTheme>
              </div>
            </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreatedOrder;
