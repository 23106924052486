import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Services } from 'Services';
import { Success } from 'Util/Toast';

const EditConfig = () => {
  const { configId, configKey: initialConfigKey, configValue: initialConfigValue } = useParams();
  const navigate = useNavigate();

  const [configKey, setConfigKey] = useState(initialConfigKey);
  const [configValue, setConfigValue] = useState(initialConfigValue);

  const handleUpdateConfig = async () => {
    try {
      const token = { Authorization: `token ${localStorage.getItem('Token')}` };
      const updatedConfig = {
        configKey,
        configValue,
        configId,
      };

      const response = await Services.updateConfigData(configId, updatedConfig, token);
      navigate('/configuration');
      Success(response.Message);
    } catch (error) {
      console.error('Error updating config data:', error);
    }
  };

  return (
    <Layout Active={'Configuration'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Configuration</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          <div className={UserClasses['add-users-btn']}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              BACK
            </Button>
          </div>
          <div className={UserClasses['users-sub-one']}>
            <div className={UserClasses['users-sub-two']}>
              <div className={UserClasses['users-div']}>
                <h4 className={UserClasses['users-heading-one']}>Update Configuration</h4>
              </div>
              <div className={UserClasses['form-group']}>
                <div className={UserClasses['form-row']}>
                  <TextField
                    key="configKey"
                    value={configKey}
                    onChange={(e) => setConfigKey(e.target.value)}
                    label="Key"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled
                  />
                </div>
                <div className={UserClasses['form-row']}>
                  <TextField
                    key="configValue"
                    value={configValue}
                    onChange={(e) => setConfigValue(e.target.value)}
                    label="Value"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
              </div>
              <Button onClick={handleUpdateConfig}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditConfig;
