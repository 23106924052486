import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import { Error } from 'Util/Toast';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';
import { IoIosAdd } from 'react-icons/io';
import { userGuideTableHeads } from '../constants/Constants';
import LoadingSpinnerComponent from 'react-spinners-components';
import GuideClasses from './UsersReferenceGuide.module.css'
import { FaRegEdit } from 'react-icons/fa';

const UsersReferenceGuide = () => {
    const [loading, setLoading] = useState(false);
    const [userGuide, setUserGuide] = useState(null);
    const navigate = useNavigate('');

    useEffect(() => {
        setLoading(true);
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        Services.getUserGuideTutorials(token)
            .then(response => {
                setUserGuide(response.userGuide);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                Error("Failed to fetch user guide tutorials");
                console.error('Error fetching user guide tutorials:', error);
            });
    }, []);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const handleUpdateClick = (userGuideId) => {
        navigate(`/editusersreferenceguide/${userGuideId}`);
    };

    const openFullScreen = (url) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            newWindow.document.body.style.zoom = "100%";
            newWindow.document.body.style.width = "100%";
            newWindow.document.body.style.height = "100%";
        }
    };

    return (
        <Layout Active={'UsersReferenceGuide'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users Reference Guide</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['users-sub-one']}>
                        <div className={UserClasses['users-sub-two']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>User Reference Guide</h4>
                            </div>
                            <div>
                                <div style={{ overflowY: 'hidden' }}>
                                    <table className={UserClasses['Table']}>
                                        <thead>
                                            <tr>
                                                {userGuideTableHeads.map((head, index) => (
                                                    <th key={index} className='users-table-data'>{head}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={userGuideTableHeads.length}>
                                                        <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                                                    </td>
                                                </tr>
                                            ) : userGuide ? (
                                                <tr className={GuideClasses['img-main-url']}>
                                                    {userGuide.userGuideUrl.endsWith('.pdf') ? (
                                                        <td onClick={() => openFullScreen(userGuide.userGuideUrl)}>
                                                            <embed src={userGuide.userGuideUrl} type="application/pdf" width="60" height="60" /><span className={GuideClasses['span-guideurl']}>click to view</span>
                                                        </td>
                                                    ) : (
                                                        <td onClick={() => openFullScreen(userGuide.userGuideUrl)}>
                                                            <img className={GuideClasses['url-img']} src={userGuide.userGuideUrl} width="60" height="60" />
                                                        </td>
                                                    )}
                                                    <td>{formatDate(userGuide.createdAt)}</td>
                                                    <td>{formatDate(userGuide.updatedAt)}</td>
                                                    <td>
                                                        <button
                                                            className={UserClasses['plan-update-btn']}
                                                            onClick={() => {
                                                                if (AccessControl(AccessKeys.UsersReferenceGuide)?.edit === 1) {
                                                                    handleUpdateClick(userGuide.userGuideId)
                                                                };
                                                            }}
                                                        >
                                                            <FaRegEdit />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan={userGuideTableHeads.length}>
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default UsersReferenceGuide;
