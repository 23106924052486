import React from 'react';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import { Success } from 'Util/Toast';

const CreateNewConfig = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState, reset } = useForm();
    const { errors } = formState;

    const onSubmit = (data) => {
        // Call the addConfigData API here
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        const configData = {
            configKey: data.name,
            configValue: data.value,
        };

        Services.addConfigData(configData, token)
            .then((response) => {
                if (response.Status === 1) {
                console.log("Config Data Added Successfully", response);
                reset();

                navigate('/configuration');

                Success(response.Message)
            }else {
                console.error("Error adding Config Data - Status: ", response.Status);
            }
        })
            .catch((error) => {
                console.error("Error adding Config Data", error);
            });
    };

    return (
        <Layout Active={'Configuration'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create New Config</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-sub-one']}>
                        <div className={UserClasses['users-sub-two']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>Add Configuration</h4>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className={UserClasses["form-group"]}>
                                        <div className={UserClasses["form-row"]}>
                                            <TextField
                                                key="name"
                                                {...register('name', { required: 'Name is required' })}
                                                label="Key"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            {errors.name && <span className={UserClasses["error-message"]}>{errors.name.message}</span>}
                                        </div>
                                        <div className={UserClasses["form-row"]}>
                                            <TextField
                                                {...register('value', { required: 'Value is required' })}
                                                label="Value"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            {errors.value && <span className={UserClasses["error-message"]}>{errors.value.message}</span>}
                                        </div>
                                    </div>
                                    <div className={UserClasses["form-group"]}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CreateNewConfig;
