import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { AiOutlineClose } from "react-icons/ai";
import UserClasses from './Users.module.css';
import { Services } from 'Services';
import { Success } from 'Util/Toast';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ModalComponent = ({ isOpen, closeModal, selectedProduct }) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    
    const maxSteps = selectedProduct?.productImageList?.length;
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [isApproveButtonActive, setIsApproveButtonActive] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldRerender, setShouldRerender] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleProductCloseClick = () => {
        setShowCommentBox(false);
        setIsApproveButtonActive(true);
        closeModal();
        setErrorMessage('');
        setShouldRerender(false);
    };

    return (
        isOpen && (
            <div className={UserClasses['modal-overlay']}>
                <div className={UserClasses['modal-content']} data-aos="zoom-in">
                    <button className={UserClasses['product-close-btn']} onClick={handleProductCloseClick}>
                        <AiOutlineClose />
                    </button>
                    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            <Typography className={UserClasses['head-modal-mui']}>{selectedProduct.productName}</Typography>
                        </Paper>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {selectedProduct.productImageList.map((image, index) => (
                                <div key={index}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 255,
                                                display: 'block',
                                                maxWidth: 400,
                                                overflow: 'hidden',
                                                width: '100%',
                                            }}
                                            src={image}
                                            alt={`${selectedProduct.productName} ${index + 1}`}
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    Next
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </div>
            </div>
        )
    );
};

export default ModalComponent;
