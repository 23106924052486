import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import UserClasses from './Users.module.css';
import { Services } from 'Services';
import TeamClasses from '../account/AdminSettings.module.css';
import Shipsy from '../../../Assets/SHIPSY_LOGO_BIRD_BLUE.png';
import { Button } from '@mui/material';
import { Success, Error } from 'Util/Toast'; // Assuming you have an Error toast component

import AddUserClasses from './adduser/AddUser.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const PasswordModal = ({ userId, isPasswordModalOpen, closeModal, onChangePassword }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track if passwords match

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        const userResponse = await Services.getEachUser(userId, token);
        setData(userResponse.user);
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };

    fetchData();
  }, [userId]);

  const handleSave = async () => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    // Validate input fields
    if (!newPassword || newPassword.length < 6) {
      Error('Password must be at least 6 characters long.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordsMatch(false); // Set passwordsMatch state to false if passwords don't match
      return;
    }

    setLoading(true);

    try {
      const response = await Services.updateUserPassword(userId, newPassword, token);
      console.log('Password updated successfully', response);

      setNewPassword('');
      setConfirmNewPassword('');
      closeModal();
      Success(response.Message);
    } catch (error) {
      console.error('Error updating password', error);
    } finally {
      setLoading(false);
    }
  };

  const getAccountTypeLabel = (accountType) => {
    switch (accountType) {
      case 1:
        return 'Manufacturer';
      case 2:
        return 'Distributor';
      case 3:
        return 'Retailer';
      case 5:
        return 'Agent';
      default:
        return 'Unknown Type';
    }
  };

  useEffect(() => {
    AOS.init({
        duration: 1000, // Duration of animation
        once: true, // Whether animation should happen only once
        // Add more options as needed
    });
}, []);

  return (
    <div className={UserClasses['custom-modal-password']}>
      <div className={UserClasses['modal-main-pass']} data-aos="zoom-in">
        <div className={UserClasses['modal-content-password']}>
          <span className={UserClasses['close']} onClick={closeModal}>
            &times;
          </span>
          <div className={AddUserClasses['form-header']}>
            CHANGE ACCOUNT PASSWORD..!
          </div>
          <div className={UserClasses['input-pass-div']}>
            <img src={Shipsy} className={TeamClasses['signupLogo']} alt="Shipsy Logo" data-aos="fade-right" />
            <h2 className={TeamClasses['formTitle']}>
              {data && data.name && data.accountType !== undefined
                ? `${data.name} (${getAccountTypeLabel(data.accountType)})!`
                : 'Loading...'}
            </h2>
            <div className={UserClasses['div-for-pass-inp']}>
              <FaLock className={UserClasses['falock']} />
              <input
                className={UserClasses['pass-change-input']}
                type={showNewPassword ? 'text' : 'password'}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setPasswordsMatch(true); // Reset passwordsMatch state when input changes
                }}
              />
              <button
                className={UserClasses['toggle-password-btn']}
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            <div className={UserClasses['div-for-pass-inp']}>
              <FaLock className={UserClasses['falock']} />
              <input
                className={UserClasses['pass-change-input']}
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                  setPasswordsMatch(true); // Reset passwordsMatch state when input changes
                }}
              />
              <button
                className={UserClasses['toggle-password-btn']}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            {!passwordsMatch && ( // Render error message if passwords don't match
              <p className={UserClasses['error-message-password']}>Passwords do not match.</p>
            )}
            <Button onClick={handleSave} disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
