import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { AiOutlineClose } from "react-icons/ai";
import { Success } from 'Util/Toast';
import { subscriptionPlansTableHeads } from '../constants/Constants';
import { FaRegEdit } from "react-icons/fa";
import LoadingSpinnerComponent from 'react-spinners-components';
import UpdateSubscriptionForm from './UpdateSubscriptionForm';
import { FaEdit } from "react-icons/fa";
import { RiAddBoxFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { AccessKeys } from 'Routes/config';
import { AccessControl } from '../Util/AccessControl';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SubscriptionPlans = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updateFormIndex, setUpdateFormIndex] = useState(null);
    const [updateFormData, setUpdateFormData] = useState({
        baseUsers: '',
        monthlyPrice: '',
        pricePerExtraUser: '',
        description: '',
    });
    const [showChangeContentModal, setShowChangeContentModal] = useState(false);
    const [changeContentFormData, setChangeContentFormData] = useState({
        mainTitle: '',
        subTitle: '',
        description: '',
        monthlyPrice: '',
    });
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);


    useEffect(() => {
        const fetchSubscriptionPlans = async () => {
            try {
                setLoading(true)
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getSubscriptionPlans(token);
                setData(response.subscriptionPlans);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
                setLoading(false);
            }
        };

        fetchSubscriptionPlans();
    }, []);

    const handleUpdateClick = (index) => {
        setUpdateFormIndex(index);
        const selectedSubscription = data[index];
        setUpdateFormData({
            baseUsers: selectedSubscription.baseUsers,
            monthlyPrice: selectedSubscription.monthlyPrice,
            pricePerExtraUser: selectedSubscription.pricePerExtraUser,
            description: selectedSubscription.description,
        });
    };

    const handleCancelUpdate = () => {
        setUpdateFormIndex(null);
        setUpdateFormData({
            baseUsers: '',
            monthlyPrice: '',
            pricePerExtraUser: '',
            description: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (index) => {
        try {
            setLoading(true)
            const selectedSubscription = data[index];
            const response = await Services.updateSubscriptionPlan(
                selectedSubscription.subscriptionId,
                updateFormData,
                { Authorization: `token ${localStorage.getItem('Token')}` }
            );

            console.log('Update Subscription Response:', response);

            if (response.Status === 1) {
                setData((prevData) => {
                    const newData = [...prevData];
                    newData[index] = {
                        ...selectedSubscription,
                        ...updateFormData,
                    };
                    return newData;
                });

                setUpdateFormData({
                    baseUsers: '',
                    monthlyPrice: '',
                    pricePerExtraUser: '',
                    description: '',
                });

                setUpdateFormIndex(null);
                Success(response.Message)
                setLoading(false)
            } else {
                setLoading(false)
                console.error('Update failed:', response.Message);
            }
        } catch (error) {
            setLoading(false)
            console.error('Error updating subscription plan:', error);
            // Handle error, show toast, etc.
        }
    };

    const handleShowChangeContentModal = async (subscriptionId) => {
        try {
            // Fetch subscription plan details based on subscriptionId
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.getSubscriptionPlanById(subscriptionId, token);

            if (response.Status === 1) {
                const subscriptionDetails = response.subscriptionPlan;

                // Accessing terms and title
                const terms = subscriptionDetails.conditions.terms;
                const title = subscriptionDetails.conditions.title;

                // Set the modal form data with fetched details
                setChangeContentFormData({
                    mainTitle: subscriptionDetails.mainTitle,
                    subTitle: subscriptionDetails.subTitle,
                    description: subscriptionDetails.description,
                    monthlyPrice: subscriptionDetails.monthlyPrice,
                    terms, // Include terms in the formData
                    title, // Include title in the formData
                });

                setSelectedSubscriptionId(subscriptionId);  // Store the subscriptionId

                setShowChangeContentModal(true);
            } else {
                console.error('Failed to fetch subscription plan details:', response.Message);
                // Handle the error, show a message, etc.
            }
        } catch (error) {
            console.error('Error fetching subscription plan details:', error);
            // Handle the error, show a message, etc.
        }
    };



    const handleCloseChangeContentModal = () => {
        setShowChangeContentModal(false);
        // You can reset the form data for change content modal here if needed
    };

    const handleSaveChangeContentModal = async () => {
        try {
            if (!selectedSubscriptionId) {
                console.error('Subscription ID is not defined');
                // Handle the error or show a message
                return;
            }

            // Prepare the payload with the updated data
            const payload = {
                mainTitle: changeContentFormData.mainTitle,
                subTitle: changeContentFormData.subTitle,
                description: changeContentFormData.description,
                monthlyPrice: changeContentFormData.monthlyPrice,
                terms: changeContentFormData.terms,
                title: changeContentFormData.title,
            };

            // Wrap the terms array inside a conditions object
            const updatedPayload = {
                ...payload,
                conditions: {
                    terms: payload.terms,
                    title: payload.title,
                },
            };

            // Make the API call to update the subscription plan
            const response = await Services.updateSubscriptionPlan(
                selectedSubscriptionId,  // Use the stored subscriptionId
                updatedPayload,
                { Authorization: `token ${localStorage.getItem('Token')}` }
            );

            if (response.Status === 1) {
                // Close the modal
                handleCloseChangeContentModal();
                Success(response.Message)

                // ... (any additional logic or feedback for success)
            } else {
                // Handle the case where the update was not successful
                console.error('Update failed:', response.Message);
                // ... (any additional logic or feedback for failure)
            }

            // ... (existing code)
        } catch (error) {
            // ... (existing code)
        }
    };


    const handleChangeContentInputChange = (e) => {
        const { name, value } = e.target;
        setChangeContentFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTermsChange = (e, index) => {
        const updatedTerms = [...changeContentFormData.terms];
        updatedTerms[index] = e.target.value;
        setChangeContentFormData((prevData) => ({
            ...prevData,
            terms: updatedTerms,
        }));
    };

    const handleAddInputField = () => {
        setChangeContentFormData((prevData) => ({
            ...prevData,
            terms: [...prevData.terms, ''], // Add a new empty string for the new input field
        }));
    };

    const handleRemoveTerm = (indexToRemove) => {
        setChangeContentFormData((prevData) => {
            const updatedTerms = [...prevData.terms];
            updatedTerms.splice(indexToRemove, 1);
            return {
                ...prevData,
                terms: updatedTerms,
            };
        });
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);


    return (
        <Layout Active={'SubscriptionPlans'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Subscription Plans</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['users-sub-one']}>
                        {loading ? (
                            <div className={UserClasses['spinner-container']}>
                                <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                            </div>
                        ) : data.length > 0 ? (
                            <div>
                                <div className={UserClasses['users-sub-two']}>
                                    <div className={UserClasses['users-div']}>
                                        <h4 className={UserClasses['users-heading-one']}>Subscription Plans</h4>
                                    </div>

                                    <div>
                                        <SkeletonTheme color="#ddd" highlightColor="#eee">
                                            {loading ? (
                                                <div>
                                                    <Skeleton height={30} width={150} />
                                                    <Skeleton count={5} />
                                                </div>
                                            ) : (
                                                <div style={{ overflowY: 'hidden' }}>
                                                    <table className={UserClasses['Table']}>
                                                        <thead>
                                                            <tr>
                                                                {subscriptionPlansTableHeads.map((head, index) => (
                                                                    <th key={index} className='users-table-data'>{head}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((item, index) => (
                                                                <React.Fragment key={item.userId}>
                                                                    <tr>
                                                                        <td>{item.accountType === 1 ? 'Manufacturer' : 'Distributor'}</td>
                                                                        <td>{item.baseUsers}</td>
                                                                        <td>{item.monthlyPrice}</td>
                                                                        <td>{item.pricePerExtraUser}</td>
                                                                        <td>
                                                                            <button className={UserClasses['subscription-cont-change']} onClick={() => handleShowChangeContentModal(item.subscriptionId)}>
                                                                                Change Subscription Content
                                                                            </button>

                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className={UserClasses['plan-update-btn']}
                                                                                onClick={() => { if (AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1) { handleUpdateClick(index) } }}
                                                                            >
                                                                                <FaRegEdit />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    {updateFormIndex !== null && (
                                                                        <UpdateSubscriptionForm
                                                                            updateFormData={updateFormData}
                                                                            handleInputChange={handleInputChange}
                                                                            handleFormSubmit={() => handleFormSubmit(updateFormIndex)}
                                                                            handleCancelUpdate={handleCancelUpdate}
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <table className={UserClasses['Table']}>
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            No Records Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {showChangeContentModal && (
                <div className={UserClasses['my-modal-overlay']}>
                    <div className={UserClasses['my-modal-content-one']} data-aos="flip-left">
                        <div className={UserClasses['my-main-close-btn']}>
                            <button className={UserClasses['myclose-btn']} onClick={handleCloseChangeContentModal}>
                                <AiOutlineClose />
                            </button>
                        </div>
                        <form className={UserClasses['my-form']}>
                            <div className={UserClasses['my-label-one']}>
                                <FaEdit className={UserClasses['cont-maintitle-icon']} />
                                {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 ? (
                                    <input
                                        type="text"
                                        name="mainTitle"
                                        className={UserClasses['my-input-head-one']}
                                        value={changeContentFormData.mainTitle}
                                        onChange={handleChangeContentInputChange}
                                    />
                                ) : (
                                    <div>
                                        <input
                                            type="text"
                                            name="mainTitle"
                                            className={UserClasses['my-input-head-one']}
                                            value={changeContentFormData.mainTitle}
                                            disabled // Disable the input field when edit permission is not granted
                                        />
                                    </div>
                                )}

                            </div>
                            <label className={UserClasses['my-label-one']}>
                                DESCRIPTION:
                                {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 ? (
                                    <input
                                        type="text"
                                        name="description"
                                        className={UserClasses['my-input-one']}
                                        value={changeContentFormData.description}
                                        onChange={handleChangeContentInputChange}
                                    />
                                ) : (
                                    <div>
                                        <input
                                            type="text"
                                            name="description"
                                            className={UserClasses['my-input-one']}
                                            value={changeContentFormData.description}
                                            disabled // Disable the input field when edit permission is not granted
                                        />
                                    </div>
                                )}

                            </label>
                            <div className={UserClasses['my-label-two']} style={{ overflowX: 'hidden', height: "15.5rem" }}>
                                <FaEdit className={UserClasses['cont-maintitle-icon-two']} />
                                {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 ? (
                                    <input
                                        type="text"
                                        name="title"
                                        className={UserClasses['my-change-subtitle']}
                                        value={changeContentFormData.title}
                                        onChange={handleChangeContentInputChange}
                                    />
                                ) : (
                                    <div>
                                        <input
                                            type="text"
                                            name="title"
                                            className={UserClasses['my-change-subtitle']}
                                            value={changeContentFormData.title}
                                            disabled // Disable the input field when edit permission is not granted
                                        />
                                    </div>
                                )}
                                {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 ? (
                                    <RiAddBoxFill
                                        className={UserClasses['cont-title-icon']}
                                        onClick={handleAddInputField}
                                    />
                                ) : ''}
                                <div className={UserClasses['my-label-three']}>
                                    <ul>
                                        {changeContentFormData.terms.map((term, index) => (
                                            <li key={index}>
                                                {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 ? (
                                                    <input
                                                        type="text"
                                                        name={`terms[${index}]`}
                                                        className={UserClasses['my-input-terms']}
                                                        value={term}
                                                        onChange={(e) => handleTermsChange(e, index)}
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name={`terms[${index}]`}
                                                        className={UserClasses['my-input-terms']}
                                                        value={term}
                                                        style={{ color: 'white' }}
                                                        disabled // Disable the input field when edit permission is not granted
                                                    />
                                                )}

                                                {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 ? (
                                                    <button
                                                        className={UserClasses['remove-term-btn']}
                                                        onClick={() => handleRemoveTerm(index)}
                                                    >
                                                        <MdCancel />
                                                    </button>
                                                ) : ''}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            {AccessControl(AccessKeys.SubscriptionPlans)?.edit === 1 && (
                                <div className={UserClasses['my-save']}>
                                    <button className={UserClasses['my-button']} type="button" onClick={handleSaveChangeContentModal}>
                                        Save Changes
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            )}

        </Layout>
    );
};

export default SubscriptionPlans;
