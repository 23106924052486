import { Error_Dark } from "./Util/Toast";
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,

        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.Status === 0) {
                if (
                    res.Message === "Invalid Token" ||
                    res.Message === "Token expired"
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                    Error_Dark("Token Expired");
                }
            }
            return res;
        });
};

const Login = (method, body) => {
    return httpcall("admin/adminLogin", method, body);
};
const getAllUsers = (userId, token) => {
    return httpcall(`admin/getUsersByAccountType/${userId}`, "GET", null, token);
};
const getEachUser = (userId, token) => {
    return httpcall(`admin/getUser/${userId}`, "GET", null, token);
};
const getallproducts = (userId, token) => {
    return httpcall(`admin/getallproducts/${userId}`, "GET", null, token);
};
const getGroups = (groupId, token) => {
    return httpcall(`admin/getUserGroups/${groupId}`, "GET", null, token)
}
const getGroupUsers = (groupId, token) => {
    return httpcall(`admin/getGroupUsers/${groupId}`, "GET", null, token)
}
const getGroupProducts = (groupId, token) => {
    return httpcall(`admin/getUserGroupProducts/${groupId}`, "GET", null, token)
}
const getSuppliers = (userId, token) => {
    return httpcall(`admin/getUsers/${userId}`, "GET", null, token)
}

const getAllUsersWithToken = (token) => {
    return httpcall("admin/getallusers", "GET", null, token);
};

// const getCompanyTypes = (method, body) => {
//     return httpcall(`users/verifySignupOtp`, method, body)
// }

const getCompanyTypes = (token) => {
    return httpcall("users/getCompanyTypes", "GET", null, token);
};

const createUser = (body, token) => {
    return httpcall("admin/createuser", "POST", body, token);
};

const getAllOrders = (token) => {
    return httpcall("admin/getallorders", "GET", null, token);
};


const getOrdersBySupplierOrdersStatus = (suplierOrdersStatus, token) => {
    return httpcall(`admin/getOrdersBySupplierOrdersStatus/${suplierOrdersStatus}`, "GET", null, token);
};

const getOrderDetails = (orderId, token) => {
    return httpcall(`admin/getOrderDetails/${orderId}`, "GET", null, token);
};

const getSubscriptionPlans = (token) => {
    return httpcall("admin/getSubscriptionPlans", "GET", null, token);
}

const AddSubscription = (body, token) => {
    return httpcall("admin/addNewSubscriptionPaln", "POST", body, token);
};

const updateSubscriptionPlan = (subscriptionId, body, token) => {
    return httpcall(`admin/updateSubscriptionPlan/${subscriptionId}`, "PATCH", JSON.stringify(body), token);
};

const deleteSubscriptionPlan = (subscriptionId, token) => {
    const url = `admin/deleteSubscription/${subscriptionId}`;

    return httpcall(url, 'PATCH', null, token);
};

const approveOrRejectProductImage = (productId, imageApproveStatus, imageRejectReason, token) => {
    const url = `admin/approveOrRejectProductImage/${productId}`;
    const body = JSON.stringify({
        imageApproveStatus,
        imageRejectReason
    });

    return httpcall(url, 'PATCH', body, token);
};

const blockUnblockUser = (userId, status, token) => {
    const url = 'admin/blockUnblockUser';
    const body = JSON.stringify({
      userId,
      status,
    });
  
    return httpcall(url, 'PATCH', body, token);
  };

const updateAdminPassword = (body, token) => {
    return httpcall("admin/updatePassword", "POST", JSON.stringify(body), token);
};  


const updateUserPassword = (userId, newPassword, token) => {
    const url = `admin/updateUserPassword`;
    const body = JSON.stringify({
        userId: userId,
        newPassword: newPassword,
    });

    return httpcall(url, 'POST', body, token);
};

const getUserOrders = (userId, accountType, token) => {
    return httpcall(`admin/userOrders/${userId}/${accountType}`, "GET", null, token);
};

const createAdminsTeamMember = (body, token) => {
    return httpcall("admin/createTeamMember", "POST", JSON.stringify(body), token);
};

const getTeamMembers = (token) => {
    return httpcall("admin/getTeamMembers", "GET", null, token);
};

const updateTeamPermissions = (data, token) => {
    const { adminId, name, email, permissions } = data;
    const url = `admin/updateTeamPermissions/${adminId}`;

    return httpcall(url, 'PATCH', JSON.stringify({ name, email, permissions }), token);
};

const deleteTeamMember = (adminId, token) => {
    const url = `admin/deleteTeamMember/${adminId}`;

    return httpcall(url, 'DELETE', null, token);
};

const getMySubscriptions = (userId, token) => {
    const url = `admin/getMySubscriptions/${userId}`;
    
    return httpcall(url, 'GET', null, token);
};

const getBillDetailsById = (monthlyPaymentId, token) => {
    const url = `admin/billDetailsById/${monthlyPaymentId}`;
    
    return httpcall(url, 'GET', null, token);
};

const renewUserSubscription = (body, token) => {
    return httpcall("admin/renewUserSubscription", "POST", JSON.stringify(body), token);
};

const getConfigData = (token) => {
    return httpcall("admin/getConfigData", "GET", null, token);
};

const addConfigData = (body, token) => {
    return httpcall("admin/addConfigData", "POST", JSON.stringify(body), token);
};

const updateConfigData = (configId, body, token) => {
    const url = `admin/updateConfigData`;
    return httpcall(url, 'PATCH', JSON.stringify(body), token);
};

const getTickets = (token) => {
    return httpcall("admin/getTickets", "GET", null, token);
};

const resolveTicket = (supportId, token, remark) => {
    const url = `admin/resolveTickets/${supportId}`;

    // Construct the request body with the remark as a string
    const requestBody = {
        remark: remark
    };

    return httpcall(url, 'PATCH', JSON.stringify(requestBody), token);
};

const getDashboardData = (type, FromDate, ToDate, token) => {
    const url = 'admin/dashBoard';
    const body = JSON.stringify({
      type,
      FromDate,
      ToDate,
    });
  
    return httpcall(url, 'POST', body, token);
  };

const getSubscriptionPlanById = (subscriptionId, token) => {
    const url = `admin/getSubscriptionPlanById/${subscriptionId}`;
    
    return httpcall(url, 'GET', null, token);
};

const getPendingBills = (token) => {
    return httpcall("admin/getPendingBills", "GET", null, token);
};

const getCancelledSubscriptionBills = (token) => {
    return httpcall("admin/getCancelledSubscriptionBills", "GET", null, token);
};

const getProductPriceMeasuringUnit = (token) => {
    return httpcall("admin/getProductPriceMeasuringUnit", "GET", null, token);
};

const getProductSubCategories = (token) => {
    return httpcall("admin/getProductSubCategories", "GET", null, token);
};

const addProductSubCategory = (payload, token) => {
    const body = JSON.stringify(payload);
    

    return httpcall("admin/addProductSubCategory", "POST", body, token);
};

const updateProductSubCategory = (payload, token) => {
    const url = "admin/updateProductSubCategories";
    const body = JSON.stringify(payload);

    return httpcall(url, 'PATCH', body, token);
};

const deleteProductSubCategory = (productSubCategoryId, token) => {
    const url = `admin/deleteProductSubCategories/${productSubCategoryId}`;
    return httpcall(url, 'DELETE', null, token);
};



const updateProductPriceMeasuringUnit = (productPriceId, productPriceUnit, token) => {
    const url = `admin/updateProductPriceMeasuringUnit/${productPriceId}`;
    const body = JSON.stringify({
        productPriceUnit: productPriceUnit
    });

    return httpcall(url, 'PATCH', body, token);
};

const deleteProductPriceMeasuringUnit = (productPriceId, token) => {
    const url = `admin/deleteProductPriceMeasuringUnit/${productPriceId}`;
    return httpcall(url, 'DELETE', null, token);
};

const addProductPriceMeasuringUnit = (productPriceUnit, token) => {
    const body = JSON.stringify({
        productPriceUnit: productPriceUnit
    });

    return httpcall("admin/addProductPriceMeasuringUnit", "POST", body, token);
};

const getProductCategoryMeasuringUnit = (token) => {
    return httpcall("admin/productCategories", "GET", null, token);
};

const updateProductCategoryMeasuringUnit = (productCategoryId, categoryName, token) => {
    const url = `admin/updateProductCategories/${productCategoryId}`;
    const body = JSON.stringify({
        categoryName: categoryName
    });

    return httpcall(url, 'PATCH', body, token);
};

const addProductCategoryMeasuringUnit = (categoryName, token) => {
    const body = JSON.stringify({
        categoryName: categoryName
    });

    return httpcall("admin/addproductCategories", "POST", body, token);
};

const deleteProductCategoryMeasuringUnit = (productCategoryId, token) => {
    const url = `admin/deleteProductCategories/${productCategoryId}`;
    return httpcall(url, 'DELETE', null, token);
};

const addUserGuideTutorials = (userGuideUrl, token) => {
    const body = JSON.stringify({
        userGuideUrl: userGuideUrl
    });

    return httpcall("admin/addUserGuideTutorials", "POST", body, token);
};

const getUserGuideTutorials = (token) => {
    return httpcall("admin/getUserGuideTutorials", "GET", null, token);
};

const updateUserGuideTutorials = (userGuideId, userGuideUrl, token) => {
    const url = `admin/updateUserGuideTutorials/${userGuideId}`;
    const body = JSON.stringify({
        userGuideUrl: userGuideUrl
    });

    return httpcall(url, 'PATCH', body, token);
};

export const Services = {
    Login,
    getAllUsers,
    getEachUser,
    getallproducts,
    getGroups,
    getGroupProducts,
    getGroupUsers,
    getSuppliers,
    getAllUsersWithToken,
    getCompanyTypes,
    createUser,
    getAllOrders,
    getOrdersBySupplierOrdersStatus,
    getOrderDetails,
    getSubscriptionPlans,
    AddSubscription,
    updateSubscriptionPlan,
    deleteSubscriptionPlan,
    approveOrRejectProductImage,
    blockUnblockUser,
    updateAdminPassword,
    updateUserPassword,
    getUserOrders,
    createAdminsTeamMember,
    getTeamMembers,
    updateTeamPermissions,
    deleteTeamMember,
    getMySubscriptions,
    getBillDetailsById,
    renewUserSubscription,
    getConfigData,
    addConfigData,
    updateConfigData,
    getTickets,
    resolveTicket,
    getDashboardData,
    getSubscriptionPlanById,
    getPendingBills,
    getCancelledSubscriptionBills,
    getProductPriceMeasuringUnit,
    updateProductPriceMeasuringUnit,
    deleteProductPriceMeasuringUnit,
    addProductPriceMeasuringUnit,
    getProductCategoryMeasuringUnit,
    updateProductCategoryMeasuringUnit,
    addProductCategoryMeasuringUnit,
    deleteProductCategoryMeasuringUnit,
    addUserGuideTutorials,
    getUserGuideTutorials,
    updateUserGuideTutorials,
    getProductSubCategories,
    addProductSubCategory,
    updateProductSubCategory,
    deleteProductSubCategory,
};
