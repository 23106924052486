import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from './UsersReferenceGuide.module.css';
import UserClasses from '../users/Users.module.css'
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import S3 from 'react-s3';
import { Success } from 'Util/Toast';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';
import { IoIosAdd } from 'react-icons/io';
import { MdEdit, MdClose } from "react-icons/md";
import { Button } from '@mui/material';

window.Buffer = window.Buffer || require('buffer').Buffer;

const EditUsersReferenceGuide = () => {
    const navigate = useNavigate();
    const { userGuideId } = useParams(); // Get userGuideId from URL parameters
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); // State to store the selected file
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'userGuide',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp' ||
            files.type === 'application/pdf'
        ) {
            setSelectedFile(files); // Store the selected file
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split('/')[1]}`
            );

            S3.uploadFile(newfile, {
                ...config,
                dirName: 'userGuide',
            })
                .then((res) => {
                    setValue('image', `${timestamp}.${String(files.type).split('/')[1]}`);
                    setValue('userGuideUrl', res.location); // Set userGuideUrl in the form data
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG, JPEG nor PDF`);
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null); // Clear the selected file
    };

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            // Splitting the URL and getting the last part (filename)
            const filename = data.userGuideUrl.split('/').pop();
            // Updating data with just the filename
            data.userGuideUrl = filename;
    
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.updateUserGuideTutorials(userGuideId, data.userGuideUrl, token); // Pass userGuideId as parameter
            if (response.Status === 1) {
                setLoading(false);
                navigate('/usersreferenceguide');
                Success(response.Message);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error updating user guide tutorial:', error);
            setLoading(false);
        }
    };
    
    

    return (
        <Layout Active={'UsersReferenceGuide'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Reference Guide</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={CommonClasses['Container']}>
                        <div className={TutorClasses['wrapper']}>
                            <h3>Edit User Reference Guide</h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={TutorClasses['file-upload-wrapper']}>
                                    <header>
                                        <h3>
                                            Upload Pdf <span className="important">*</span>
                                        </h3>
                                    </header>
                                    <div className={TutorClasses["upload-image"]}>

                                        <label htmlFor="image" className={TutorClasses["add-item"]} >
                                            <BsCloudUpload className={TutorClasses["upload-icon"]} />
                                            <h5>Replace Your Files here</h5>
                                            <p>(Only *.jpeg ,*.png will be accepted)</p>
                                            <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
                                        </label>

                                    </div>
                                    <div className={TutorClasses["preview-wrapper"]}>
                                        {selectedFile && (
                                            <div className={TutorClasses["preview-container"]}>
                                                {selectedFile.type.startsWith('image') ? (
                                                    <img src={URL.createObjectURL(selectedFile)} alt="Preview" width="200" height="200" />
                                                ) : (
                                                    <embed src={URL.createObjectURL(selectedFile)} type="application/pdf" width="200" height="200" />
                                                )}
                                                <button type="button" className={TutorClasses["remove-button"]} onClick={handleRemoveFile}>
                                                    <MdClose />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Add more input fields for other properties */}
                                <button disabled={loading}>
                                    {loading ? (
                                        <ReactLoading color="green" type="spokes" height={30} width={30} />
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditUsersReferenceGuide;
