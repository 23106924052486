import Layout from 'Components/Layout'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AccessControl } from '../Util/AccessControl'
import { AccessKeys } from 'Routes/config'
import UserClasses from '../users/Users.module.css'
import { IoIosAdd } from 'react-icons/io'
import { productCategoryTableHeads, productPriceTableHeads, productSubCategoryTableHeads } from '../constants/Constants'
import LoadingSpinnerComponent from 'react-spinners-components'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Services } from 'Services'
import { Success } from 'Util/Toast'
import { MdOutlineClose } from 'react-icons/md'
import productmoduleclasses from './ProductPrice.module.css'
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProductSubCategory = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedProductPriceId, setSelectedProductPriceId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedProductPriceUnit, setSelectedProductPriceUnit] = useState('');
    const [editedProductPriceUnit, setEditedProductPriceUnit] = useState('');
    const [newUnit, setNewUnit] = useState('');
    const [openAddUnitModal, setOpenAddUnitModal] = useState(false);
    const [selectedDeleteProductPriceId, setSelectedDeleteProductPriceId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedProductCategory, setSelectedProductCategory] = useState('');
    const [newSubCategory, setNewSubCategory] = useState('');
    const [productCategories, setProductCategories] = useState([]);
    const [editedProductSubCategoryName, setEditedProductSubCategoryName] = useState('');
    const [selectedProductSubCategoryId, setSelectedProductSubCategoryId] = useState(null);
    const [selectedDeleteProductSubCategoryId, setSelectedDeleteProductSubCategoryId] = useState(null);

    const fetchData = async () => {
        try {
            setLoading(true);
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.getProductSubCategories(token);
            setData(response.getProductSubCategories);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching product price measuring units:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        

        fetchData();
    }, []);

    useEffect(() => {
        const fetchCatData = async () => {
            try {
                setLoading(true);
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getProductCategoryMeasuringUnit(token);
                setProductCategories(response.productCategories);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching product categories:', error);
                setLoading(false);
            }
        };

        fetchCatData();
    }, []);

    const handleEditClick = (productSubCategoryId, productCategoryId, subCategoryName) => {
        setSelectedProductSubCategoryId(productSubCategoryId); // Store the subcategory ID
        setSelectedProductCategory(productCategoryId);
        setEditedProductSubCategoryName(subCategoryName);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleUpdateClick = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const payload = {
                subCategoryName: editedProductSubCategoryName,
                productCategoryId: selectedProductCategory,
                productSubCategoryId: selectedProductSubCategoryId // Include the subcategory ID
            };
            const response = await Services.updateProductSubCategory(payload, token);

            if (response.Status === 1) {
                const updatedData = data.map(item => {
                    if (item.productSubCategoryId === selectedProductSubCategoryId) {
                        return { ...item, subCategoryName: editedProductSubCategoryName };
                    }
                    return item;
                });
                setData(updatedData);
                fetchData()

                Success(response.Message);
            } else {
                console.error('Error updating product subcategory:', response.Message);
            }

            setOpenModal(false);
        } catch (error) {
            console.error('Error updating product subcategory:', error);
        }
    };

    const handleAddUnitClick = () => {
        setOpenAddUnitModal(true);
    };

    const handleCloseAddUnitModal = () => {
        setOpenAddUnitModal(false);
        setSelectedProductCategory(''); // Clear the selected product category
        setNewSubCategory(''); // Clear the new subcategory input
    };

    const handleAddUnitSubmit = async () => {
        try {
            if (!selectedProductCategory || !newSubCategory) {
                alert('Please select a product category and enter a subcategory name.');
                return;
            }

            const token = { Authorization: `token ${localStorage.getItem('Token')}` };

            const payload = {
                subCategoryName: newSubCategory,
                productCategoryId: selectedProductCategory
            };

            const response = await Services.addProductSubCategory(payload, token);

            const newSubCategoryData = {
                productCategoryId: selectedProductCategory,
                subCategoryName: newSubCategory
            };
            // setData([...data, newSubCategoryData]);
            fetchData()
            handleCloseAddUnitModal();
            console.log('Product subcategory added successfully');
            Success(response.Message);
        } catch (error) {
            console.error('Error adding product subcategory:', error);
        }
    };

    const handleDeleteClick = (productSubCategoryId) => {
        setSelectedDeleteProductSubCategoryId(productSubCategoryId);
        setOpenDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.deleteProductSubCategory(selectedDeleteProductSubCategoryId, token);

            if (response.Status === 1) {
                const updatedData = data.filter(item => item.productSubCategoryId !== selectedDeleteProductSubCategoryId);
                setData(updatedData);
                Success(response.Message);
            } else {
                console.error('Error deleting product subcategory:', response.Message);
            }

            setOpenDeleteModal(false);
        } catch (error) {
            console.error('Error deleting product subcategory:', error);
        }
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    return (
        <Layout Active={'ProductSubCategory'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ProductSubCategory</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    {AccessControl(AccessKeys.ProductCategory)?.write === 1 && (
                        <div className={UserClasses['add-users-btn']}>
                            <button className={UserClasses['add-user']} onClick={handleAddUnitClick}>
                                <IoIosAdd className={UserClasses['add-plus']} />
                                ADD SUB CATEGORY
                            </button>
                        </div>
                    )}
                    <div className={UserClasses['users-sub-one']}>
                        <div className={UserClasses['users-sub-two']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>Product Sub Category</h4>
                            </div>
                            <div>
                                <div style={{ overflowY: 'hidden' }}>
                                    <table className={UserClasses['Table']}>
                                        <thead>
                                            <tr>
                                                {productSubCategoryTableHeads.map((head, index) => (
                                                    <th key={index} className='users-table-data'>{head}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={productSubCategoryTableHeads.length}>
                                                        <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                                                    </td>
                                                </tr>
                                            ) : data.length > 0 ? (
                                                data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.categoryName}</td>
                                                        <td>{item.subCategoryName}</td>
                                                        <td>
                                                            <button className={productmoduleclasses['plan-update-prod-btn']} onClick={() => { if (AccessControl(AccessKeys.ProductPrice)?.edit === 1) { handleEditClick(item.productSubCategoryId, item.productCategoryId, item.subCategoryName) } }}><FaRegEdit /></button>
                                                            <button className={productmoduleclasses['plan-delete-prod-btn']} onClick={() => { if (AccessControl(AccessKeys.ProductPrice)?.edit === 1) { handleDeleteClick(item.productSubCategoryId) } }}><AiFillDelete /></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={productCategoryTableHeads.length}>
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={openModal} onClose={handleCloseModal} data-aos="zoom-in">
                <Box className={productmoduleclasses['modal-container']}>
                    <div className={productmoduleclasses['modal-header']}>
                        <Typography className={productmoduleclasses['product-typo']} variant="h6" component="h2">
                            Edit Product Sub Category
                        </Typography>
                        <button onClick={handleCloseModal} className={productmoduleclasses['close-button']}>
                            <MdOutlineClose />
                        </button>
                    </div>
                    <FormControl fullWidth className={productmoduleclasses['prod-typo-textfield']}>
                        <InputLabel>Product Category</InputLabel>
                        <Select
                            value={selectedProductCategory}
                            onChange={(e) => setSelectedProductCategory(e.target.value)}
                            label="Product Category"
                        >
                            {productCategories.map((category) => (
                                <MenuItem key={category.productCategoryId} value={category.productCategoryId}>
                                    {category.categoryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <TextField
                        label="SubCategory Name"
                        value={editedProductSubCategoryName} // Use the correct state here
                        onChange={(e) => setEditedProductSubCategoryName(e.target.value)} // Update the correct state
                        className={productmoduleclasses['prod-typo-textfield']}
                    />
                    <br />
                    <div className={productmoduleclasses['prod-typo-butn']}>
                        <Button variant="contained" color="primary" onClick={handleUpdateClick}>Update</Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={openAddUnitModal} onClose={handleCloseAddUnitModal} data-aos="zoom-in">
                <Box className={productmoduleclasses['modal-container']}>
                    <div className={productmoduleclasses['modal-header']}>
                        <Typography className={productmoduleclasses['product-typo']} variant="h6" component="h2">
                            Add Product SubCategory
                        </Typography>
                        <button onClick={handleCloseAddUnitModal} className={productmoduleclasses['close-button']}>
                            <MdOutlineClose />
                        </button>
                    </div>
                    <FormControl fullWidth className={productmoduleclasses['prod-typo-textfield']}>
                        <InputLabel>Product Category</InputLabel>
                        <Select
                            value={selectedProductCategory}
                            onChange={(e) => setSelectedProductCategory(e.target.value)}
                            label="Product Category"
                        >
                            {productCategories.map((category) => (
                                <MenuItem key={category.productCategoryId} value={category.productCategoryId}>
                                    {category.categoryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <TextField
                        label="SubCategory Name"
                        value={newSubCategory}
                        onChange={(e) => setNewSubCategory(e.target.value)}
                        className={productmoduleclasses['prod-typo-textfield']}
                        fullWidth
                    />
                    <br />
                    <div className={productmoduleclasses['prod-typo-butn']}>
                        <Button variant="contained" color="primary" onClick={handleAddUnitSubmit}>Submit</Button>
                    </div>
                </Box>
            </Modal>
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} data-aos="zoom-in">
                <Box className={productmoduleclasses['modal-container']}>
                    <Typography className={productmoduleclasses['product-typo']} variant="h6" component="h2">
                        Confirm Deletion
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Are you sure you want to delete this Product SubCategory?
                    </Typography>
                    <div className={productmoduleclasses['prod-typo-butn-new']}>
                        <Button variant="contained" color="primary" onClick={handleDeleteConfirm}>Confirm</Button>
                        <Button variant="outlined" color="secondary" onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                    </div>
                </Box>
            </Modal>
        </Layout>
    )
}

export default ProductSubCategory
