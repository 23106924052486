import React from "react";
import Skeleton from "react-loading-skeleton";
import UserClasses from './Users.module.css'
import loadClasses from "../../../Components/TableLoad/load.module.css";

function UsersSkeleton ({ TableHeader }) {
    return (
        <div className={loadClasses['Table']}>
            <table>
                <thead>
                    <tr>
                        {TableHeader.map((item, index) => (
                            <th key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                     {Array(3)
                        .fill("0")
                        .map(() => (
                            <tr>
                                {Array(TableHeader.length)
                                    .fill("0")
                                    .map(() => (
                                        <td>
                                            <Skeleton height={"1rem"} width={100}/>
                                        </td>
                                    ))}
                            </tr>
                        ))} 
                </tbody>
            </table>
        </div>
    );
}

export default UsersSkeleton;