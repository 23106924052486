
import React from 'react'
import { Navigate, } from "react-router-dom";


const Gaurd = ({ permissions, element, Type }) => {
  // function to check if the user has required permissions
  function hasPermissions(Name, Type) {

    let storageData = JSON.parse(localStorage.getItem("AccessItems"));

    let requiredItem = storageData.filter(
      (item) => item.name === Name
    );
    if (requiredItem && requiredItem[0].Permissions[Type] === 1) {
      return true;
    }
    return false;
  }
  return hasPermissions(permissions, Type) ? element :
    <Navigate to="/forbidden" replace />
}

export default Gaurd