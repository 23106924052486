import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import TicketClasses from '../support/TicketDetails.module.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { Services } from 'Services';
import { Success } from 'Util/Toast';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';

const TicketDetails = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const location = useLocation();
    const ticket = location.state?.ticket;

    const [comment, setComment] = useState('');

    useEffect(() => {
        if (!ticket) {
            navigate('/complaints');
        }
    }, [ticket, navigate]);

    const token = {
        Authorization: `token ${localStorage.getItem('Token')}`
    };

    const handleResolveClick = async () => {
        try {
            const response = await Services.resolveTicket(
                ticket.supportId,
                token,
                comment // Pass the comment directly
            );

            if (response.Status === 1) {
                Success(response.Message);
                console.log('Ticket resolved successfully');
                navigate('/complaints');
            } else {
                console.error('Error resolving ticket:', response.Message);
            }
        } catch (error) {
            console.error('Error resolving ticket:', error);
        }
    };

    return (
        <Layout Active={'Complaints'}>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={TicketClasses['ticket-users-main']}>
                        <h2 className={UserClasses['users-heading-one']}>{ticket.companyName}</h2>
                        <div className={TicketClasses['ticket-users-div']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>
                                    Complaint Details ({ticket.resolved === 0 ? 'Pending' : 'Resolved'})
                                </h4>
                                <p className={TicketClasses['descript-ticket']}>Description:</p>
                                <div className={TicketClasses['ticket-textarea-div']}>
                                    <textarea
                                        className={TicketClasses['ticket-description']}
                                        disabled
                                    >
                                        {ticket.description}
                                    </textarea>
                                </div>

                                <div style={{ overflowY: 'hidden' }}>
                                    <div className={TicketClasses['image-ticket-main']}>
                                        <SlideshowLightbox className={TicketClasses['image-ticket-sub']}>
                                            {ticket.images.map((image, index) => (
                                                <img key={index} src={image} alt={`Image ${index}`} />
                                            ))}
                                        </SlideshowLightbox>
                                    </div>
                                </div>

                                {AccessControl(AccessKeys.Complaints)?.edit === 1 && ticket.resolved === 0 && (
                                    <>
                                        <p className={TicketClasses['descript-ticket']}>Comment:</p>
                                        <div className={TicketClasses['ticket-textarea-div']}>
                                            <textarea
                                                className={TicketClasses['ticket-description']}
                                                placeholder="Add your comments..."
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                        </div>
                                    </>
                                )}

                                {AccessControl(AccessKeys.Complaints)?.edit === 1 && ticket.resolved === 0 && (
                                    <div className={TicketClasses['resolve-btn-main']}>
                                        <button className={TicketClasses['btn-resolve']} onClick={handleResolveClick}>
                                            Resolve
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default TicketDetails;
