import Layout from 'Components/Layout'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import UserClasses from '../users/Users.module.css';
import { Services } from 'Services';
import { formatDate } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import LoadingSpinnerComponent from 'react-spinners-components';

const Complaints = () => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                setLoading(true)
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getTickets(token);
                setTickets(response.tickets);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching tickets:', error);
                setLoading(false)
            }
        };

        fetchTickets();
    }, []);


    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const getAccountTypeLabel = (accountType) => {
        switch (accountType) {
            case 1:
                return 'Manufacturer';
            case 2:
                return 'Distributor';
            case 3:
                return 'Retailer';
            case 5:
                return 'Agent';
            default:
                return 'Unknown Type';
        }
    };

    const handleTableRowClick = (ticket) => {
        const userId = String(ticket.userId);
        navigate(`/ticket-details/${userId}`, { state: { ticket } });
    };

    // below is the sorting method to sort all the tickets and show the pending tickets first and then show the resolved tickets.
    const sortedTickets = [...tickets].sort((a, b) => a.resolved - b.resolved);

    return (
        <Layout Active={'Complaints'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Complaints</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['users-sub-one']}>
                        {loading ? (
                            <div className={UserClasses['spinner-container']}>
                                <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                            </div>
                        ) : tickets.length > 0 ? (
                            <div>
                                <div className={UserClasses['users-sub-two']}>
                                    <div className={UserClasses['users-div']}>
                                        <h4 className={UserClasses['users-heading-one']}>Complaints</h4>
                                        <div style={{ overflowY: 'hidden' }}>
                                        <table className={UserClasses['Table']}>
                                            <thead>
                                                <tr>
                                                    <th>COMPANY NAME</th>
                                                    <th>ISSUE TITLE</th>
                                                    <th>ACCOUNT TYPE</th>
                                                    <th>CREATED AT</th>
                                                    <th>UPDATED AT</th>
                                                    <th>STATUS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedTickets.map(ticket => (
                                                    <tr key={ticket.id} onClick={() => handleTableRowClick(ticket)}>
                                                        <td>{ticket.companyName || 'N/A'}</td>
                                                        <td>{ticket.title}</td>
                                                        <td>{getAccountTypeLabel(ticket.accountType)}</td>
                                                        <td>{new Intl.DateTimeFormat('en-US', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            second: 'numeric',
                                                            hour12: false, // Use 24-hour format
                                                        }).format(new Date(ticket.createdAt))}</td>
                                                        <td>{new Intl.DateTimeFormat('en-US', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            second: 'numeric',
                                                            hour12: false, // Use 24-hour format
                                                        }).format(new Date(ticket.updatedAt))}</td>
                                                        <td>
                                                            <button
                                                                className={UserClasses['ticket-btn']}
                                                                style={{
                                                                    backgroundColor: ticket.resolved === 0 ? '#FF7474' : '#8BE78B',
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                {ticket.resolved === 0 ? 'Pending' : 'Resolved'}
                                                            </button>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <table className={UserClasses['Table']}>
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            No Records Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Complaints;
