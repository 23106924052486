import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from './Users.module.css';
import { Helmet } from 'react-helmet';
import { IoIosAdd } from "react-icons/io";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { userTableHeads } from '../constants/Constants';
import Load from './UsersSkeleton';
import LoadingSpinnerComponent from 'react-spinners-components';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';
import { IoSearchSharp } from "react-icons/io5";
import { useSearchParams } from 'react-router-dom';

const ITEMS_PER_PAGE = 10;

const AccountSettings = () => {
  const [searchparams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const urlPage = new URLSearchParams(location.search).get('page') || 1;
  const [currentPage, setCurrentPage] = useState(parseInt(urlPage, 10));
  const [searchQuery, setSearchQuery] = useState('');


  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const urlActiveTab = searchparams.get('activeTab') || 1;
        const urlPage = searchparams.get('page') || 1;

        setActiveTab(parseInt(urlActiveTab, 10));
        setCurrentPage(parseInt(urlPage, 10));

        const accountTypeMapping = {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
        };

        const accountType = accountTypeMapping[urlActiveTab] || ''; // Get the actual account type
        if (accountType) {
          localStorage.setItem('AccountType', accountType);
        } else {
          localStorage.removeItem('AccountType');
        }

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        const result = await Services.getAllUsers(urlActiveTab, token, searchQuery);

        if (result.isActive === 0) {
          throw new Error('Error fetching data. Please try again');
        }

        setData(result.users);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    // Fetch data whenever activeTab changes
    fetchData();
  }, [location.search, currentPage, searchQuery, activeTab]);


  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    navigate(`${location.pathname}?page=1&activeTab=${tab}`);
  };

  const handleToggleSwitch = async (userId, currentStatus) => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await Services.blockUnblockUser(userId, newStatus, token);

      setData((prevData) =>
        prevData.map((item) =>
          item.userId === userId ? { ...item, status: newStatus } : item
        )
      );
    } catch (error) {
      console.error('Error blocking/unblocking user:', error.message);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Update the URL with the new page and active tab
    navigate(`${location.pathname}?page=${pageNumber}&activeTab=${activeTab}&search=${searchQuery}`);

  };


  function getAccountTypeText(accountType) {
    switch (accountType) {
      case 1:
        return 'Manufacturer';
      case 2:
        return 'Distributor';
      case 3:
        return 'Retailer';
      case 4:
        return 'Marketing Specialist';
      case 5:
        return 'Agent';
      case 6:
        return 'Office Team';
      case 7:
        return 'All Users';
      default:
        return '';
    }
  }

  return (
    <Layout Active={'Account Settings'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Users</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>

          <div className={UserClasses['search-main']}>
            {AccessControl(AccessKeys.Users)?.write === 1 && (
              <div className={UserClasses['add-users-btn']}>
                <NavLink className={UserClasses['navlink']} to={`/add-user`}>
                  <button className={UserClasses['add-user']} >
                    <IoIosAdd className={UserClasses['add-plus']} />
                    ADD USERS
                  </button>
                </NavLink>
              </div>
            )}
            <div className={UserClasses['search-users-btn']}>
              <p><IoSearchSharp /></p>
              <input
                type="text"
                placeholder="UID, MOBILE OR NAME"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div className={UserClasses['users-sub-one']}>
            <div className={UserClasses['users-sub-two']}>
              <div className={UserClasses['users-div']}>
                <h4 className={UserClasses['users-heading-one']}>Users</h4>
              </div>
              <div className={UserClasses['users-heading-two']}>
                <button
                  className={activeTab === 1 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(1)}
                >
                  Manufacturer
                </button>
                <button
                  className={activeTab === 2 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(2)}
                >
                  Distributor
                </button>
                <button
                  className={activeTab === 3 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(3)}
                >
                  Retailer
                </button>
                <button
                  className={activeTab === 4 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(4)}
                >
                  Marketing Specialist
                </button>
                <button
                  className={activeTab === 5 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(5)}
                >
                  Agents
                </button>
                <button
                  className={activeTab === 6 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(6)}
                >
                  Office Team
                </button>
                <button
                  className={activeTab === 7 ? UserClasses.active : UserClasses.inactive}
                  onClick={() => handleTabClick(7)}
                >
                  All Users
                </button>
              </div>

              <div>
                <div style={{ overflowY: 'hidden' }}>
                  {loading ? (
                    <Load TableHeader={[
                      'UID',
                      'NAME',
                      'MOBILE',
                      'BUSINESS NAME',
                      'SHOP STATUS',
                    ]} />
                  ) : data.length > 0 ? (
                    <div>
                      <table className={UserClasses['Table']}>
                        <thead>
                          <tr>
                            <th>UID</th>
                            <th>NAME</th>
                            {localStorage.AccountType === '7' && (
                              <th>ACCOUNT TYPE</th>)}
                            <th>MOBILE</th>
                            <th>BUSINESS NAME</th>
                            {(activeTab === 1 || activeTab === 2 || activeTab === 7) && <th>SHOP STATUS</th>}
                            {AccessControl(AccessKeys.Users)?.edit ===
                              1 && <th>BLOCK UNBLOCK</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {data
                            .filter((item) =>
                              (String(item.UID).includes(searchQuery) || item.mobileNumber.includes(searchQuery) || item.name.toLowerCase().includes(searchQuery.toLowerCase()))
                            )

                            .slice(startIndex, endIndex)
                            .map((item) => (
                              <tr key={item.userId}>
                                <td>
                                  <NavLink className={UserClasses['navlink']} to={`/each-user/${item.userId}`}>
                                    {item.UID}
                                  </NavLink>
                                </td>
                                <td>
                                  <NavLink className={UserClasses['navlink']} to={`/each-user/${item.userId}`}>
                                    {item.name}
                                  </NavLink>
                                </td>

                                {localStorage.AccountType === '7' && (
                                  <td>
                                    <NavLink className={UserClasses['navlink']} to={`/each-user/${item.userId}`}>
                                      {getAccountTypeText(item.accountType)}
                                    </NavLink>
                                  </td>
                                )}
                                <td>
                                  <NavLink className={UserClasses['navlink']} to={`/each-user/${item.userId}`}>
                                    {item.mobileNumber}
                                  </NavLink>
                                </td>

                                <td>
                                  <NavLink className={UserClasses['navlink']} to={`/each-user/${item.userId}`}>
                                    {item.businessName}
                                  </NavLink>
                                </td>
                          
                                {((activeTab === 1 || activeTab === 2 || activeTab === 7) && (
                                  <td>
                                    {((item.accountType === 1 || item.accountType === 2) ? (
                                      <button
                                        className={`${UserClasses['account-btn']} ${item.isActive === 1
                                          ? UserClasses['status-active-btn']
                                          : UserClasses['status-inactive-btn']
                                          }`}
                                      >
                                        {item.isActive === 0 ? 'InActive' : 'Active'}
                                      </button>
                                    ) : "-")}
                                  </td>
                                ))}

                                {AccessControl(AccessKeys.Users)?.edit === 1 && (
                                  <td>
                                    <label className={UserClasses['switch']}>
                                      <input
                                        type="checkbox"
                                        checked={item.status === 1}
                                        onChange={() => handleToggleSwitch(item.userId, item.status)}
                                      />
                                      <span className={`${UserClasses['slider']} ${UserClasses['round']}`}></span>
                                      <div className={`${UserClasses['block-user-text']} ${item.status === 1 ? UserClasses['block-text-green'] : UserClasses['block-text-red']}`}>
                                        {item.status === 1 ? 'Block User' : 'Unblock User'}
                                      </div>
                                    </label>
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <div className={UserClasses['pagination-container']}>
                        {currentPage > 1 && (
                          <button
                            className={UserClasses['pagination-btn']}
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            &#8592; {/* Left arrow */}
                          </button>
                        )}

                        {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => (
                          pageNumber > 0 && pageNumber <= Math.ceil(data.length / ITEMS_PER_PAGE) && (
                            <button
                              key={pageNumber}
                              className={currentPage === pageNumber ? UserClasses.activePage : UserClasses.page}
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          )
                        ))}

                        {currentPage < Math.ceil(data.length / ITEMS_PER_PAGE) && (
                          <button
                            className={UserClasses['pagination-btn']}
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            &#8594; {/* Right arrow */}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <table className={UserClasses['Table']}>
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            No Records Found
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AccountSettings;
