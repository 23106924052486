import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Services } from 'Services';
import { ImNotification } from 'react-icons/im';
import { Success } from 'Util/Toast';
import AOS from 'aos';
import 'aos/dist/aos.css';

const BillDetails = () => {
    const navigate = useNavigate();
    const { monthlyPaymentId } = useParams(); // Get the monthlyPaymentId from the URL parameters
    const [billDetails, setBillDetails] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState(''); // State to store comment
    const isValidDate = (date) => !isNaN(date.getTime());

    useEffect(() => {
        const fetchBillDetails = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                // Fetch bill details using the monthlyPaymentId from the URL
                const result = await Services.getBillDetailsById(monthlyPaymentId, token);
                setBillDetails(result.bill); // Set the bill details in state
            } catch (error) {
                console.error('Error fetching bill details:', error.message);
            }
        };

        fetchBillDetails();
    }, [monthlyPaymentId]);

    const handlePayNowClick = () => {
        setShowModal(true);
    };

    const handleConfirmationClose = () => {
        setShowModal(false);
    };

    const handleRenewButtonClick = async () => {
        try {
            // Call API to renew subscription with the comment
            if (!comment) {
                console.error('Remark comment is required');
                return;
            }

            const renewSubscriptionInputs = {
                userId: billDetails.userId,
                accountType: billDetails.accountType,
                monthlyPaymentId: monthlyPaymentId,
                adminComment: comment,
                paidByAdmin: 1,
            };

            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.renewUserSubscription(renewSubscriptionInputs, token);
            console.log('Renewal Response:', response);

            // Close the modal after successful renewal
            navigate(-1)
            Success(response.Message)
            setShowModal(false);
        } catch (error) {
            console.error('Error confirming renewal:', error.message);
        }
    };

    const formatDate = (dateString) => {
        const options = { month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);

    return (
        <Layout Active={'Account Settings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bill Details</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['add-users-btn-new']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                        {billDetails.paymentStatus === 0 && (
                            <div className={UserClasses['add-users-btn']}>
                                <Button variant="contained" color="secondary" onClick={handlePayNowClick}>
                                    Pay Now
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className={UserClasses['users-sub-one']}>
                        <div className={UserClasses['users-sub-two']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>Bill Details</h4>
                            </div>
                            <div className={UserClasses['users-heading-two']}>
                                <table className={UserClasses['Table']}>
                                    <thead>
                                        <tr>
                                            <th>Total Users</th>
                                            <th>Total Amount</th>
                                            <th>Month</th>
                                            <th>Payment Status</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{billDetails.totalUsers}</td>
                                            <td>{Math.round(billDetails.totalAmount)}</td>
                                            <td>
                                                {formatDate(billDetails.toDate)}
                                            </td>
                                            <td>{billDetails.paymentStatus === 0 ? 'UnPaid' : 'Paid'}</td>
                                            <td>{billDetails.adminComment || 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className={UserClasses['renew-modal']}>
                    <div className={UserClasses['renew-modal-content']} data-aos="flip-left">
                        <h1><ImNotification className={UserClasses['mark-icon']} /></h1>
                        <h4>Mark as cash collected</h4>
                        <p>And Confirm to renew for another month.</p>
                        <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Write your comment..."
                            className={UserClasses['comment-input']}
                            style={{ width: '100%', height: '100px' }}
                        />


                        <br />
                        <button className={UserClasses['renew-button-confirm']} onClick={handleRenewButtonClick}>Confirm</button>
                        <button className={UserClasses['renew-button-cancel']} onClick={handleConfirmationClose}>Cancel</button>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default BillDetails;
