import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { IoIosAdd } from 'react-icons/io';
import { NavLink, useNavigate } from 'react-router-dom';
import { createConfigTableHeads } from '../constants/Constants';
import { FaRegEdit } from "react-icons/fa";
import LoadingSpinnerComponent from 'react-spinners-components';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';

const Configuration = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        setLoading(true)
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        const response = await Services.getConfigData(token);
        setData(response.config);
        console.log(response)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        setLoading(false);
      }
    };

    fetchConfigData();
  }, []);


  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const EditConfigButton = ({ configId, configKey, configValue, hasEditPermission }) => {
    const navigate = useNavigate();

    const handleEditConfig = () => {
      if (hasEditPermission) {
        navigate(`/editconfig/${configId}/${configKey}/${configValue}`);
      } else {
        // Handle case when user doesn't have edit permission
        console.log("You don't have permissions to edit this config.");
      }
    };

    return (
      <button className={UserClasses['plan-update-btn']} onClick={handleEditConfig}>
        <FaRegEdit />
      </button>
    );
  };

  return (
    <Layout Active={'Configuration'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuration</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          <div className={UserClasses['users-sub-one']}>
            {loading ? (
              <div className={UserClasses['spinner-container']}>
                <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
              </div>
            ) : data.length > 0 ? (
              <div className={UserClasses['users-sub-two']}>
                <div className={UserClasses['users-div']}>
                  <h4 className={UserClasses['users-heading-one']}>Configurations</h4>
                </div>
                <div>
                  <SkeletonTheme color="#ddd" highlightColor="#eee">
                    {loading ? (
                      <div>
                        <Skeleton height={30} width={150} />
                        <Skeleton count={5} />
                      </div>
                    ) : (
                      <div style={{ overflowY: 'hidden' }}>
                        <table className={UserClasses['Table']}>
                          <thead>
                            <tr>
                              {createConfigTableHeads.map((head, index) => (
                                <th key={index} className='users-table-data'>{head}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => (
                              <React.Fragment key={item.userId}>
                                <tr>
                                  <td>{item.configKey}</td>
                                  <td>{item.configValue}</td>
                                  <td>{formatDate(item.createdAt)}</td>
                                  <td>{formatDate(item.updatedAt)}</td>
                                  <td>
                                    <EditConfigButton
                                      configId={item.configId}
                                      configKey={item.configKey}
                                      configValue={item.configValue}
                                      hasEditPermission={AccessControl(AccessKeys.Configuration)?.edit === 1}
                                    />
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </SkeletonTheme>
                </div>
              </div>
            ) : (
              <table className={UserClasses['Table']}>
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      No Records Found
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Configuration;
