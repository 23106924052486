import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from './Users.module.css';
import { Helmet } from 'react-helmet';
import { IoIosAdd } from "react-icons/io";
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import defaultImage from 'Assets/square-user.jpg'
import { MdDriveFileRenameOutline } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { PiPhoneFill } from "react-icons/pi";
import ModalComponent from './ModalComponent';
import SupplierModal from './SupplierModal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { createdOrdersTableHeads, groupsTableHeads, productsTableHeads, recievedOrdersTableHeads, suppliersTableHeads } from '../constants/Constants';
import PasswordModal from './PasswordModal';
import { IoPerson } from 'react-icons/io5';
import { IoLocationSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { FaRupeeSign } from "react-icons/fa";
import { AiTwotoneLock } from "react-icons/ai";
import { FaLock } from "react-icons/fa6";
import { Button } from '@mui/material';
import UsersSkeleton from './UsersSkeleton';
import Switch from 'react-switch';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';
import Denied from 'pages/AccessDenied/denied';
import { IoIosSettings } from "react-icons/io";
import { Success } from 'Util/Toast';
import { ImNotification } from "react-icons/im";
import { SlideshowLightbox } from 'lightbox.js-react';

const getAccountTypeLabel = (accountType) => {
    switch (accountType) {
        case 1:
            return 'Manufacturer';
        case 2:
            return 'Distributor';
        case 3:
            return 'Retailer';
        case 4:
            return 'Marketing Specialist';
        case 5:
            return 'Agents';
        case 6:
            return 'Office Team';
        default:
            return 'Unknown Account Type';
    }
};

const statusLabels = {
    0: 'Pending',
    1: 'Processing',
    2: 'Invoice Printed',
    3: 'Delivered',
    4: 'Completed',
    5: 'Rejected',
};

const EachUser = () => {
    const { userId } = useParams();
    const [activeTab, setActiveTab] = useState(1);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [supplierDetails, setSupplierDetails] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [orders, setOrders] = useState([]);
    const [mySubscriptions, setMySubscriptions] = useState(null);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [remarkComment, setRemarkComment] = useState('');
    const [isRemarkBoxOpen, setIsRemarkBoxOpen] = useState(false);
    const [adminComment, setAdminComment] = useState("");
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');



    const handleConfirmRenewal = () => {
        setConfirmationOpen(true);
    };

    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };

    const navigate = useNavigate();

    const openSupplierModal = async (supplier) => {
        setSelectedSupplier(supplier);
        setIsSupplierModalOpen(true);

        try {
            const result = await Services.getSuppliers(userId, token);
            setSupplierDetails(result);
        } catch (error) {
            console.error('Error fetching supplier details:', error.message);
        }
    };

    const openModal = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedProduct(null);
        setIsModalOpen(false);
    };

    const closeSupplierModal = () => {
        setSelectedSupplier(null);
        setIsSupplierModalOpen(false);
    };

    const handleRemarkBoxClose = () => {
        setIsRemarkBoxOpen(false);
    };

    const location = useLocation();


    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);


                const urlActiveTab = new URLSearchParams(location.search).get('activeTab') || 1;
                setActiveTab(parseInt(urlActiveTab, 10));

                if (activeTab === 1) {
                    const result = await Services.getEachUser(userId, token);
                    setData(result.user);

                    if (result.user.accountType === 1) {
                        localStorage.setItem('AccountType', result.user.accountType);
                    }

                } else if (activeTab === 2) {
                    const productResults = await Services.getallproducts(userId, token);
                    setProducts(productResults.products)

                } else if (activeTab === 3) {
                    const groupResults = await Services.getGroups(userId, token);
                    setGroups(groupResults.groups)
                } else if (activeTab === 4) {
                    const supplierResults = await Services.getSuppliers(userId, token);
                    setSuppliers(supplierResults.users)
                } else if (activeTab === 5) {
                    // Get accountType from local storage
                    const accountType = localStorage.getItem('AccountType');
                    const userOrders = await Services.getUserOrders(userId, accountType, token);
                    console.log('User Orders:', userOrders);
                    setOrders(userOrders.orders);
                } else if (activeTab === 8) {
                    const userOrders = await Services.getUserOrders(userId, 8, token);
                    console.log('User Orders:', userOrders);
                    setOrders(userOrders.orders);
                } else if (activeTab === 9) {
                    // Call getMySubscriptions API
                    const mySubscriptionsResponse = await Services.getMySubscriptions(userId, token);
                    console.log('My Subscriptions:', mySubscriptionsResponse);

                    // Assuming mySubscriptionsResponse is an object with the structure { Status, bills, mySubscription }
                    setMySubscriptions(mySubscriptionsResponse);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 200)
            }
        };

        if (activeTab) {
            fetchData();
        }
    }, [activeTab, userId, location.search]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`${location.pathname}?activeTab=${tab}`);
    };

    const handleTableRowClick = (productId) => {
        const productClicked = products.find(product => product.productId === productId);

        if (productClicked) {
            openModal(productClicked);
        }
    };

    // const formatDate = (dateString) => {
    //     const options = { day: 'numeric', month: 'short', year: 'numeric' };
    //     const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    //     return formattedDate;
    // };

    const formatDate = (dateString) => {
        const options = { month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    // Example usage:
    const date = '2024-05-04';
    console.log(formatDate(date)); // Output: "May 2024"


    const handleUserTableRowClick = (userId) => {
        const userClicked = suppliers.find((supplier) => supplier.userId === userId);

        if (userClicked) {
            openSupplierModal(userClicked);
        }
    };

    const handleChangePassword = async (oldPwd, newPwd, confirmPwd) => {
        try {
            if (newPwd !== confirmPwd) {
                console.error('New passwords do not match');
                return;
            }

            const result = await Services.changePassword(userId, oldPwd, newPwd, token);

            console.log('Password change result:', result);
            setIsPasswordModalOpen(false);
        } catch (error) {
            console.error('Error changing password:', error.message);
        }
    };
    // console.log(isPasswordModalOpen)

    const getCurrentMonth = () => {
        const dueDate = new Date(mySubscriptions.dueDate);
        const currentMonth = new Date(dueDate.getFullYear(), dueDate.getMonth() - 1, dueDate.getDate());
        return currentMonth.toLocaleString('default', { month: 'long' });
    };

    function calculateDaysToDeactivation(dueDate) {
        const dueDateObj = new Date(dueDate);
        const currentDate = new Date();

        const timeDifference = dueDateObj - currentDate;
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference;
    }

    const handleRenewButtonClick = async () => {
        try {
            setLoading(true);

            if (mySubscriptions && mySubscriptions.mySubscription) {
                console.log(mySubscriptions);

                if (isRemarkBoxOpen) {
                    console.error('Cannot renew while remark box is open');
                    return;
                }

                setIsRemarkBoxOpen(true);
            } else {
                console.log('Renewal canceled by user.');
            }
        } catch (error) {
            console.error('Error handling renewal:', error.message);
        } finally {
            setLoading(false);
            setConfirmationOpen(false);
        }
    };

    const handleConfirmRenewalWithRemark = async () => {
        try {
            if (!adminComment) {
                console.error('Remark comment is required');
                return;
            }

            const renewSubscriptionInputs = {
                userId: userId,
                accountType: mySubscriptions.mySubscription.accountType,
                monthlyPaymentId: mySubscriptions.mySubscription.monthlyPaymentId,
                adminComment: adminComment,  // Use the actual variable you have in your state
                paidByAdmin: 1,
            };

            const response = await Services.renewUserSubscription(renewSubscriptionInputs, token);
            Success(response.Message);

            const mySubscriptionsResponse = await Services.getMySubscriptions(userId, token);
            console.log('My Subscriptions:', mySubscriptionsResponse);

            setMySubscriptions(mySubscriptionsResponse);
            console.log('Renewal Response:', response);

            setIsRemarkBoxOpen(false);


        } catch (error) {
            console.error('Error confirming renewal:', error.message);
        } finally {
            setLoading(false);
        }
    };


    const isDueDateInNextMonth = () => {
        const dueDate = new Date(mySubscriptions.dueDate);
        const currentDate = new Date();

        // Check if dueDate is in the next month
        return dueDate.getMonth() === (currentDate.getMonth() + 1) % 12 &&
            dueDate.getFullYear() === (currentDate.getMonth() === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear());
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setLightboxOpen(true);
    };

    const handleCloseLightbox = () => {
        setSelectedImage('');
        setLightboxOpen(false);
    };

    return (
        <Layout Active={'Account Settings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Users</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={`${UserClasses['users-main']} ${selectedUser ? UserClasses['users-main-hidden'] : ''}`}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-sub-one']}>
                        <div className={UserClasses['users-sub-two']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>{getAccountTypeLabel(data.accountType)}:{data.name}</h4>
                            </div>
                            <div className={UserClasses['users-heading-two']}>
                                <button
                                    className={activeTab === 1 ? UserClasses.active : UserClasses.inactive}
                                    onClick={() => handleTabClick(1)}
                                >
                                    Details
                                </button>
                                {localStorage.getItem('AccountType') === '3' || localStorage.getItem('AccountType') === '4' || localStorage.getItem('AccountType') === '5' || localStorage.getItem('AccountType') === '6' ? null : (
                                    <button
                                        className={activeTab === 2 ? UserClasses.active : UserClasses.inactive}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Products
                                    </button>
                                )}
                                {localStorage.getItem('AccountType') === '3' || localStorage.getItem('AccountType') === '4' || localStorage.getItem('AccountType') === '5' || localStorage.getItem('AccountType') === '6' ? null : (
                                    <button
                                        className={activeTab === 3 ? UserClasses.active : UserClasses.inactive}
                                        onClick={() => handleTabClick(3)}
                                    >
                                        Groups
                                    </button>
                                )}
                                {localStorage.getItem('AccountType') === '3' || localStorage.getItem('AccountType') === '6' ? null : (
                                    <button
                                        className={activeTab === 4 ? UserClasses.active : UserClasses.inactive}
                                        onClick={() => handleTabClick(4)}
                                    >
                                        Users
                                    </button>
                                )}
                                {(localStorage.getItem('AccountType') === '3' || localStorage.getItem('AccountType') === '4' || localStorage.getItem('AccountType') === '5') || localStorage.getItem('AccountType') === '6' ? null : (
                                    <button
                                        className={activeTab === 5 ? UserClasses.active : UserClasses.inactive}
                                        onClick={() => handleTabClick(5)}
                                    >
                                        Received Orders
                                    </button>
                                )}
                                {localStorage.getItem('AccountType') === '1' || localStorage.getItem('AccountType') === '6' ? null : (
                                    <button
                                        className={activeTab === 8 ? UserClasses.active : UserClasses.inactive}
                                        onClick={() => handleTabClick(8)}
                                    >
                                        Created Orders
                                    </button>
                                )}
                                {localStorage.getItem('AccountType') === '3' || localStorage.getItem('AccountType') === '4' || localStorage.getItem('AccountType') === '5' || localStorage.getItem('AccountType') === '6' ? null : (AccessControl(AccessKeys.Users)?.edit === 1 && (
                                    <button
                                        className={activeTab === 9 ? UserClasses.active : UserClasses.inactive}
                                        onClick={() => handleTabClick(9)}
                                    >
                                        Subscription Details
                                    </button>
                                ))}
                            </div>
                            <div>
                                {activeTab === 1 && data && (
                                    <SkeletonTheme color="#ddd" highlightColor="#eee">
                                        <div style={{ overflowY: 'hidden' }}>
                                            {loading ? (
                                                <div className={UserClasses['user-selected']}>
                                                    <div className={UserClasses['user-inside-selected']}>
                                                        <div className={UserClasses['inside-sel-img']}>
                                                            <div>
                                                                <Skeleton height={100} width={100} />
                                                            </div>
                                                            <div className={UserClasses['skel-sub-one']}>
                                                                <Skeleton width={150} />
                                                                <Skeleton width={150} />
                                                                <Skeleton width={150} />
                                                                <Skeleton width={150} />
                                                                <Skeleton width={150} />
                                                            </div>
                                                        </div>
                                                        <div className={UserClasses['inside-sel-para']}>
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                            <Skeleton width={400} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={UserClasses['user-selected']}>
                                                    <div className={UserClasses['user-inside-selected']}>
                                                        <div key={data.userId} className={UserClasses['inside-sel-img']} onClick={() => handleImageClick(data.Photo || defaultImage)}>
                                                            <div>
                                                                <SlideshowLightbox
                                                                    isOpen={lightboxOpen}
                                                                    onClose={handleCloseLightbox}
                                                                    startIndex={0} // Index of the selected image, if you have a gallery
                                                                >
                                                                    <img src={data.Photo || defaultImage} alt="" onError={(e) => {
                                                                        e.target.src = defaultImage;
                                                                    }} />
                                                                </SlideshowLightbox>
                                                            </div>
                                                            <div>
                                                                <h4>Contact</h4>
                                                                <div className={UserClasses['selected-p-disp']}>
                                                                    <p className={UserClasses['selected-p-design']}><MdDriveFileRenameOutline /></p>
                                                                    <p>{data.name}</p>
                                                                </div>
                                                                <div className={UserClasses['selected-p-disp']}>
                                                                    <p className={UserClasses['selected-p-design']}><MdEmail /></p>
                                                                    <p className={UserClasses['email-text']}>{data.email}</p>
                                                                </div>
                                                                <div className={UserClasses['selected-p-disp']}>
                                                                    <p className={UserClasses['selected-p-design']}><PiPhoneFill /></p>
                                                                    <p>{data.mobileNumber}</p>
                                                                </div>
                                                                {AccessControl(AccessKeys.Users)?.edit === 1 && (
                                                                    <div className={UserClasses['selected-p-disp']}>
                                                                        <div className={UserClasses['user-pass-change-btn-container']}>
                                                                            <Button className={UserClasses['user-pass-change-btn']} onClick={() => setIsPasswordModalOpen(true)}>
                                                                                <IoIosSettings /><span className={UserClasses['lock-icon']}>Change Password ?</span>

                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={UserClasses['inside-sel-para']}>
                                                            <p><span className={UserClasses['label']}>Associated With:</span>{data.businessName}</p>
                                                            <p><span className={UserClasses['label']}>Account Type:</span>{getAccountTypeLabel(data.accountType)}</p>
                                                            <p><span className={UserClasses['label']}>GST Number:</span>{data.GSTNumber}</p>
                                                            <p><span className={UserClasses['label']}>Company Type:</span>{data.companyType}</p>
                                                            <p><span className={UserClasses['label']}>Address:</span>{data.address}</p>
                                                            <p><span className={UserClasses['label']}>Created At:</span>{new Intl.DateTimeFormat('en-US', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                                second: 'numeric',
                                                                hour12: false, // Use 24-hour format
                                                            }).format(new Date(data.createdAt))}</p>
                                                            <p><span className={UserClasses['label']}>Latitude:</span>{data.latitude}</p>
                                                            <p><span className={UserClasses['label']}>Longitude:</span>{data.longitude}</p>
                                                            {localStorage.getItem('AccountType') === '4' && (
                                                                <p>
                                                                    <span className={UserClasses['label']}>Assigned Manufacturer:</span>
                                                                    {data.assignedManufacturer}
                                                                </p>
                                                            )}
                                                            <p><span className={UserClasses['label']}>User Count:</span>{data.userCount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </SkeletonTheme>
                                )}
                            </div>
                            {activeTab === 2 && products.length > 0 && (
                                <div style={{ overflowY: 'hidden' }}>
                                    {loading ? (
                                        <UsersSkeleton TableHeader={productsTableHeads} />
                                    ) : (
                                        <table className={UserClasses['Table']}>
                                            <thead>
                                                <tr>
                                                    {productsTableHeads.map((head, index) => (
                                                        <th key={index} className={UserClasses['users-table-data']}>{head}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map((product) => (
                                                    <tr key={product.productId}>
                                                        <td style={{ position: 'relative' }}>
                                                            <div className={`${UserClasses['status-tag']}`}>{product.productCategory}</div>
                                                            <div>
                                                                {product.productImageList && product.productImageList.length > 0 ? (
                                                                    <img
                                                                        onClick={() => {
                                                                            if (AccessControl(AccessKeys.Users)?.edit === 1) {
                                                                                handleTableRowClick(product.productId);
                                                                            }
                                                                        }}
                                                                        src={product.productImageList[0]} // Display the first image in the array
                                                                        alt={product.productName}
                                                                        style={{ width: '50px', height: '50px', marginRight: '5px' }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        onClick={() => {
                                                                            if (AccessControl(AccessKeys.Users)?.edit === 1) {
                                                                                handleTableRowClick(product.productId);
                                                                            }
                                                                        }}
                                                                        src={product.productImage}
                                                                        alt={product.productName}
                                                                        style={{ width: '50px', height: '50px' }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td>{product.productName}</td>
                                                        <td>{product.stockQty}{product.stockMeasuringUnit}</td>
                                                        <td>
                                                            <button
                                                                className={`${UserClasses['account-btn']} ${product.status === 1
                                                                    ? UserClasses['status-active-btn']
                                                                    : UserClasses['status-inactive-btn']
                                                                    }`}
                                                            >
                                                                {product.status === 1 ? 'Active' : 'Inactive'}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}


                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                            <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} selectedProduct={selectedProduct} />
                            {activeTab === 3 && groups.length > 0 && (
                                <div style={{ overflowY: 'hidden' }}>
                                    {loading ? (
                                        <UsersSkeleton TableHeader={groupsTableHeads} />
                                    ) : (
                                        <table className={UserClasses['Table']}>
                                            <thead>
                                                <tr>
                                                    {groupsTableHeads.map((head, index) => (
                                                        <th key={index} className={UserClasses['users-table-data']}>{head}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeTab === 3 && (
                                                    groups.map((group) => (
                                                        <tr key={group.groupId}>
                                                            <td><NavLink className={UserClasses['navlink']} to={`/group-by-area/${group.groupId}`}>
                                                                {group.groupName}
                                                            </NavLink></td>
                                                            <td><NavLink className={UserClasses['navlink']} to={`/group-by-area/${group.groupId}`}>
                                                                {formatDate(group.createdAt)}
                                                            </NavLink></td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                            <SupplierModal isOpen={isSupplierModalOpen} closeModal={() => setIsSupplierModalOpen(false)} selectedSupplier={selectedSupplier} />
                            {activeTab === 4 && suppliers.length > 0 && (
                                <div style={{ overflowY: 'hidden' }}>
                                    {loading ? (
                                        <UsersSkeleton TableHeader={suppliersTableHeads} />
                                    ) : (
                                        <table className={UserClasses['Table']}>
                                            <thead>
                                                <tr>
                                                    {suppliersTableHeads.map((head, index) => (
                                                        <th key={index} className={UserClasses['users-table-data']}>{head}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {suppliers.map((supplier) => (
                                                    <tr key={supplier.userId}>
                                                        <td>{supplier.UID}</td>
                                                        <td><img
                                                            src={supplier.photo || defaultImage}
                                                            style={{ width: '40px', height: '40px', borderRadius: '50%', }}
                                                            alt={""}
                                                            onError={(e) => {
                                                                e.target.src = defaultImage;
                                                            }} /></td>
                                                        <td>{supplier.name}</td>
                                                        <td>{supplier.email}</td>
                                                        <td>{supplier.mobileNumber}</td>
                                                        <td>{supplier.businessName}</td>
                                                        <td>{getAccountTypeLabel(supplier.accountType)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                            {activeTab === 5 && (
                                <div style={{ overflowY: 'hidden' }}>
                                    {loading ? (
                                        <UsersSkeleton TableHeader={recievedOrdersTableHeads} />
                                    ) : (
                                        orders && orders.length > 0 && (
                                            <table className={UserClasses['Table']}>
                                                <thead>
                                                    <tr>
                                                        <th>UserId</th>
                                                        <th>User Name</th>
                                                        <th>Created At</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.map((order) => (
                                                        <tr key={order.orderId}>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/each-user-order/${order.orderId}`}>
                                                                    {order.userId}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/each-user-order/${order.orderId}`}>
                                                                    {order.userName}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/each-user-order/${order.orderId}`}>
                                                                    {formatDate(order.createdAt)}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/each-user-order/${order.orderId}`}>
                                                                    {statusLabels[order.userOrdersStatus]}
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )
                                    )}
                                </div>
                            )}

                            {activeTab === 8 && (
                                <div style={{ overflowY: 'hidden' }}>
                                    {loading ? (
                                        <UsersSkeleton TableHeader={createdOrdersTableHeads} />
                                    ) : (
                                        orders && orders.length > 0 && (
                                            <table className={UserClasses['Table']}>
                                                <thead>
                                                    <tr>
                                                        <th>User Name</th>
                                                        <th>Created At</th>
                                                        <th>Creared For</th>
                                                        <th>Created From</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.map((order) => (
                                                        <tr key={order.orderId}>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/created-order/${order.orderId}`}>
                                                                    {order.userName}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/created-order/${order.orderId}`}>
                                                                    {formatDate(order.createdAt)}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/created-order/${order.orderId}`}>
                                                                    {order.createdFor}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/created-order/${order.orderId}`}>
                                                                    {order.createdFrom}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <NavLink className={UserClasses['navlink']} to={`/created-order/${order.orderId}`}>
                                                                    {statusLabels[order.userOrdersStatus]}
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )
                                    )}
                                </div>
                            )}
                            {activeTab === 9 && (
                                <div>
                                    <div>
                                        {mySubscriptions && (
                                            <div>
                                                <div className={UserClasses['subscription-card']}>
                                                    <h3>Subscription Details</h3>
                                                    <div>
                                                        <div className={UserClasses['status-renew']}>
                                                            <p>
                                                                Subscription Status:
                                                                <span style={{
                                                                    color:
                                                                        mySubscriptions.cancelSubscription === 0 && mySubscriptions.isSubscribed === 1 ? 'green' :
                                                                            mySubscriptions.cancelSubscription === 0 && mySubscriptions.isSubscribed === 0 ? 'orange' :
                                                                                'red'
                                                                }}>
                                                                    {mySubscriptions.cancelSubscription === 0 && mySubscriptions.isSubscribed === 1 ? 'Active' :
                                                                        mySubscriptions.cancelSubscription === 0 && mySubscriptions.isSubscribed === 0 ? 'Inactive' :
                                                                            'Cancelled'}
                                                                </span>
                                                            </p>
                                                            {mySubscriptions.cancelSubscription !== undefined && mySubscriptions.cancelSubscription !== 0 && mySubscriptions.cancelSubscription !== '0' && (
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        backgroundColor: isDueDateInNextMonth() ? 'transparent' : 'purple',
                                                                        color: 'white',
                                                                        border: isDueDateInNextMonth() ? '1px solid white' : '1px solid transparent',
                                                                    }}
                                                                    onClick={() => setIsRemarkBoxOpen(true)}
                                                                    disabled={isDueDateInNextMonth()}
                                                                    className={`${UserClasses['disabled-button']} ${isDueDateInNextMonth() ? UserClasses['status-renew'] : ''}`}
                                                                >
                                                                    {isDueDateInNextMonth() ? null : 'Renew'}
                                                                </Button>
                                                            )}

                                                        </div>

                                                        {mySubscriptions.isSubscribed === 1 && (
                                                            <div className={UserClasses['due-date-div']}>
                                                                <p>Bill Cycle: {getCurrentMonth()}</p>
                                                                <p>Due Date: {formatDate(mySubscriptions.dueDate)}</p>
                                                                <p>
                                                                    {calculateDaysToDeactivation(mySubscriptions.dueDate) < 0 ? (
                                                                        <>
                                                                            Your subscription completed {Math.abs(
                                                                                calculateDaysToDeactivation(mySubscriptions.dueDate)
                                                                            )}{' '}
                                                                            days ago
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {calculateDaysToDeactivation(mySubscriptions.dueDate)} days
                                                                            left
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {mySubscriptions.bills && mySubscriptions.bills.length > 0 ? (
                                                    <div>
                                                        <h3>Bills</h3>
                                                        <table className={UserClasses['Table']}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Bill Id</th>
                                                                    <th>Month</th>
                                                                    <th>Payment Status</th>
                                                                    <th>Remarks</th>
                                                                    {/* Add other bill headers as needed */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {mySubscriptions.bills.map((bill) => (
                                                                    <tr key={bill.monthlyPaymentId}>
                                                                        <td>
                                                                            <NavLink to={`/bill-details/${bill.monthlyPaymentId}`} className={UserClasses['navlink']}>
                                                                                {bill.monthlyPaymentId}
                                                                            </NavLink>
                                                                        </td>
                                                                        <td>
                                                                            <NavLink to={`/bill-details/${bill.monthlyPaymentId}`} className={UserClasses['navlink']}>
                                                                                {formatDate(bill.toDate)}
                                                                            </NavLink>
                                                                        </td>
                                                                        <td>
                                                                            <NavLink to={`/bill-details/${bill.monthlyPaymentId}`} className={UserClasses['navlink']}>
                                                                                {bill.paymentStatus === 0 ? 'UnPaid' : 'Paid'}
                                                                            </NavLink>
                                                                        </td>
                                                                        <td>
                                                                            <NavLink to={`/bill-details/${bill.monthlyPaymentId}`} className={UserClasses['navlink']}>
                                                                                {bill.adminComment}
                                                                            </NavLink>
                                                                        </td>
                                                                        {/* Add other bill properties as needed */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <p>No Bill records found.</p>
                                                )}

                                            </div>
                                        )}
                                        {!mySubscriptions && loading && <p>Loading...</p>}
                                        {!mySubscriptions && !loading && <p>No data available.</p>}
                                    </div>

                                </div>
                            )}
                            {isConfirmationOpen && (
                                <div className={UserClasses['renew-modal']}>
                                    <div className={UserClasses['renew-modal-content']}>
                                        <h1><ImNotification className={UserClasses['mark-icon']} /></h1>
                                        <h4>Mark as cash collected</h4>
                                        <p>And Confirm to renew for another month.</p>
                                        <button className={UserClasses['renew-button-confirm']} onClick={handleRenewButtonClick}>Confirm</button>
                                        <button className={UserClasses['renew-button-cancel']} onClick={handleConfirmationClose}>Cancel</button>
                                    </div>
                                </div>
                            )}

                            {(!loading && activeTab !== 1 && !data) ||
                                (activeTab === 2 && !products.length) ||
                                (activeTab === 3 && !groups.length) ||
                                (activeTab === 4 && !suppliers.length) ||
                                (activeTab === 5 && !orders.length) ||
                                (activeTab === 8 && !orders.length) ? (
                                <table className={UserClasses['Table']}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                No Records Found
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {isPasswordModalOpen &&
                <PasswordModal
                    userId={userId}

                    isPasswordModalOpen={isPasswordModalOpen}
                    closeModal={() => setIsPasswordModalOpen(false)}
                    onChangePassword={handleChangePassword}
                />
            }
            {isRemarkBoxOpen && (
                <div className={UserClasses['remark-box']}>
                    <div className={UserClasses['remark-box-content']} data-aos="flip-left">
                        <h2>Pay and Renew</h2>
                        <h4>Description</h4>
                        <textarea
                            value={adminComment}
                            onChange={(e) => setAdminComment(e.target.value)}
                            placeholder="Enter remark comment..."
                        />
                        <button className={UserClasses['remark-box-cancel']} onClick={handleRemarkBoxClose}>
                            Cancel
                        </button>
                        <button className={UserClasses['remark-box-confirm']} onClick={handleConfirmRenewalWithRemark}>
                            OK
                        </button>
                    </div>
                </div>
            )}



        </Layout >
    );
};

export default EachUser;
