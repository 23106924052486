import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { useForm } from 'react-hook-form';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import UserClasses from '../users/Users.module.css';
import { BiSolidBadgeCheck } from 'react-icons/bi';
import { Services } from 'Services';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Success } from 'Util/Toast';
import LoadingSpinnerComponent from 'react-spinners-components';
import AddUserClasses from '../users/adduser/AddUser.module.css';



const AddSubscription = () => {
    const navigate = useNavigate();
    const { handleSubmit, register, formState: { errors } } = useForm();
    const [formData, setFormData] = useState({
        accountType: 1,
        baseUsers: 300,
        monthlyPrice: 5000,
        pricePerExtraUser: 10,
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let mappedValue = value;

        if (name === 'accountType') {
            // Map accountType values to codes
            mappedValue = value === 'Manufacturer' ? 1 : 2;
        }

        setFormData((prevData) => ({ ...prevData, [name]: mappedValue }));
    };

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            console.log('Form Data:', JSON.stringify(data, null, 2));
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.AddSubscription(JSON.stringify(data), token);
            console.log(response);
            navigate('/subscriptionplans');
            Success(response.Message)
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false); // Set loading back to false when submission is completed
        }
    };

    return (
        <Layout Active={'SubscriptionPlans'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Subscription</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['Add-users-sub-one']}>
                        <div className={AddUserClasses['form-header']}>
                            ADD SUBSCRIPTION HERE..!
                        </div>
                        <div className={UserClasses['Add-users-sub-two']}>
                            {loading ? (
                                <div className={UserClasses['spinner-container']}>
                                    <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit(onSubmit)} className={UserClasses['add-user-form']}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                        <InputLabel htmlFor="accountType">Account Type</InputLabel>
                                        <Select
                                            {...register('accountType', { required: 'Account Type is required' })}
                                            label="Account Type"
                                            value={formData.accountType}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={1}>Manufacturer</MenuItem>
                                            <MenuItem value={2}>Distributor</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="outlined" margin="normal">
                                        <div className={UserClasses['add-sub-new']}>
                                            <InputLabel className={UserClasses['inpt-for-addsub']} htmlFor="baseUsers">Base Package User Limit</InputLabel>
                                            <TextField
                                                {...register('baseUsers', { required: 'User Limit is required', pattern: /^\d+$/ })}
                                                type="text"
                                                value={formData.baseUsers}
                                                onChange={handleChange}
                                                fullWidth
                                                error={Boolean(errors.baseUsers)}
                                                helperText={errors.baseUsers && errors.baseUsers.message}
                                            />
                                        </div>
                                    </FormControl>

                                    <FormControl fullWidth variant="outlined" margin="normal">
                                        <div className={UserClasses['add-sub-new']}>
                                            <InputLabel className={UserClasses['inpt-for-addsub']} htmlFor="monthlyPrice">Base Price</InputLabel>
                                            <TextField
                                                {...register('monthlyPrice', { required: 'Monthly Price is required', pattern: /^\d+$/ })}
                                                type="text"
                                                value={formData.monthlyPrice}
                                                onChange={handleChange}
                                                fullWidth
                                                error={Boolean(errors.monthlyPrice)}
                                                helperText={errors.monthlyPrice && errors.monthlyPrice.message}
                                            />
                                        </div>
                                    </FormControl>

                                    <FormControl fullWidth variant="outlined" margin="normal">
                                        <div className={UserClasses['add-sub-new']}>
                                            <InputLabel className={UserClasses['inpt-for-addsub']} htmlFor="pricePerExtraUser">Price Per Extra User</InputLabel>
                                            <TextField
                                                {...register('pricePerExtraUser', { required: 'Price Per Extra User is required', pattern: /^\d+$/ })}
                                                type="text"
                                                value={formData.pricePerExtraUser}
                                                onChange={handleChange}
                                                fullWidth
                                                error={Boolean(errors.pricePerExtraUser)}
                                                helperText={errors.pricePerExtraUser && errors.pricePerExtraUser.message}
                                            />
                                        </div>
                                    </FormControl>

                                    <button className={UserClasses['form-last-btn']} type="submit">
                                        Submit
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddSubscription;
