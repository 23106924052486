import React, { useEffect } from 'react';
import UserClasses from './Users.module.css';
import { AiOutlineClose } from "react-icons/ai";
import ModalComponent from './ModalComponent'; // Import the ModalComponent
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProductModal = ({ product, onClose }) => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);

    return (
        <ModalComponent
            isOpen={true} // Pass isOpen as true to always keep the modal open
            closeModal={onClose} // Pass onClose function to handle modal close
            selectedProduct={product} // Pass the selected product to the modal component
        />
    );
};

export default ProductModal;
