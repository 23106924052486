import React from 'react';
import UserClasses from './Users.module.css';
import { Button } from '@mui/material';

const SupplierModal = ({ isOpen, closeModal, selectedSupplier, supplierDetails }) => {
    return (
        isOpen && (
            <div className={UserClasses['modal-overlay']}>
                <table className={UserClasses['supplier-modal-content']}>
                    <tbody>
                        <tr>
                            <th>Business Name:</th>
                            <td>{selectedSupplier.businessName}</td>
                        </tr>
                        {selectedSupplier.businessHours && selectedSupplier.businessHours.length > 0 && (
                            <tr>
                                <th>Business Hours:</th>
                                <td>
                                    <ul>
                                        {selectedSupplier.businessHours.map((businessHour) => (
                                            <li key={businessHour.Id}>
                                                {`${businessHour.weekday}: ${businessHour.timings}`}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="2" className={UserClasses['modal-actions']}>
                                <Button onClick={closeModal}>Close</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    );
};

export default SupplierModal;
