import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Services } from 'Services';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Success } from 'Util/Toast';
import AddUserClasses from './AddUser.module.css';
import UserClasses from '../Users.module.css';
import Layout from 'Components/Layout';
import LoadingSpinnerComponent from 'react-spinners-components'; // Add this line
import AOS from 'aos';
import 'aos/dist/aos.css';

const accountTypeOptions = [
    { label: 'Manufacturer', value: 1 },
    { label: 'Distributor', value: 2 },
    { label: 'Retailer', value: 3 },
    { label: 'Marketing Specialist', value: 4 },
    { label: 'Agent', value: 5 },
];

const AddUser = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        accountType: '',
        companyType: '',
        name: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        gstNumber: '',
        location: '',
        businessName: '',
    });

    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        setError,
        formState: { errors },
    } = useForm();
    const [companyTypes, setCompanyTypes] = useState([]);
    const [loading, setLoading] = useState(true); // Add this line

    useEffect(() => {
        const fetchCompanyTypes = async () => {
            try {
                const response = await Services.getCompanyTypes();
                setCompanyTypes(response.companyType);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching company types:', error);
            }
        };

        fetchCompanyTypes();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = () => {
        const password = watch('password');
        const confirmPassword = watch('confirmPassword');

        if (password !== confirmPassword) {
            setError('confirmPassword', {
                type: 'manual',
                message: 'Passwords do not match',
            });
        } else {
            setError('confirmPassword', null);
        }
    };


    const handleBlurPassword = () => {
        // Explicitly set the values for 'password' and 'confirmPassword' before calling validatePassword
        setValue('password', watch('password'));
        setValue('confirmPassword', watch('confirmPassword'));

        validatePassword();
    };

    const buildPayload = () => {
        if (watch('accountType') === 4 || watch('accountType') === 6) {
            return {
                name: watch('name'),
                email: watch('email'),
                mobileNumber: watch('mobile'),
                password: watch('password'),
                confirmPassword: watch('confirmPassword'),
                accountType: watch('accountType'),
            };
        }

        return {
            companyType: watch('companyType'),
            name: watch('name'),
            businessName: watch('businessName'),
            email: watch('email'),
            password: watch('password'),
            GSTNumber: watch('gstNumber'),
            mobileNumber: watch('mobile'),
            accountType: watch('accountType'),
            address: watch('location'),
            latitude: 0.0,
            longitude: 0.0,
        };
    };

    const onSubmit = async (data) => {
        // Manually trigger validation before submitting the form
        validatePassword();

        // Check if there are any validation errors
        if (Object.keys(errors).length === 0) {
            try {
                const payload = buildPayload();
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.createUser(JSON.stringify(payload), token);

                if (response.Status === 1) {
                    Success('User created successfully');
                    navigate('/account-settings');
                } else {
                    console.error('Error creating user:', response.error);
                }
            } catch (error) {
                console.error('Unexpected error creating user:', error);
            }
        }
    };
    useEffect(() => {
        // Watch for changes in 'password' and 'confirmPassword' fields
        validatePassword();
    }, [watch('password'), watch('confirmPassword')]);

    const isValidGSTIN = (gstin) => {
        const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
        return gstinRegex.test(gstin);
    };

    const isValidMobile = (mobile) => {
        const mobileRegex = /^\d{10}$/;
        return mobileRegex.test(mobile);
    };

    const validateMobileNumber = (mobileNumber) => {
        return isValidMobile(mobileNumber);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);

    return (
        <Layout Active={'Account Settings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                {loading ? (
                    <div className={UserClasses['spinner-container']}>
                        <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                    </div>
                ) : (
                    <div className={UserClasses['users-main']}>
                        <div className={UserClasses['add-users-btn']}>
                            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                                BACK
                            </Button>
                        </div>
                        <div className={UserClasses['Add-users-sub-one']} data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="500">
                            <div className={AddUserClasses['form-header']}>
                                ADD USER HERE..!
                            </div>
                            <div className={UserClasses['Add-users-sub-two']}>
                                <form onSubmit={handleSubmit(onSubmit)} className={AddUserClasses['add-user-form']}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                        <InputLabel className={UserClasses['input-create-user']} htmlFor="accountType">
                                            Account Type
                                        </InputLabel>
                                        <Select
                                            label="Account Type"
                                            inputProps={{ ...register('accountType', { required: 'Account Type is required' }) }}
                                        >
                                            {accountTypeOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.accountType && <span className={UserClasses["error-message"]}>{errors.accountType.message}</span>}
                                    </FormControl>

                                    {watch('accountType') !== '4' && watch('accountType') !== '6' && (
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel className={UserClasses['input-create-user']} htmlFor="companyType">
                                                Company Type
                                            </InputLabel>
                                            <Select
                                                label="Company Type"
                                                inputProps={{ ...register('companyType', { required: 'Company Type is required' }) }}
                                            >
                                                {companyTypes.map((type) => (
                                                    <MenuItem key={type.companytypeId} value={type.companyType}>
                                                        {type.companyType}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.companyType && <span className={UserClasses["error-message"]}>{errors.companyType.message}</span>}
                                        </FormControl>
                                    )}

                                    <TextField
                                        label="Name"
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        {...register('name', { required: 'Name is required' })}
                                    />
                                    {errors.name && <span className={UserClasses["error-message"]}>{errors.name.message}</span>}

                                    <TextField
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        {...register('email', { required: 'Email is required' })}
                                    />
                                    {errors.email && <span className={UserClasses["error-message"]}>{errors.email.message}</span>}

                                    <TextField
                                        label="Mobile"
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        {...register('mobile', {
                                            required: 'Mobile is required',
                                            validate: {
                                                isValidMobile: (value) =>
                                                    /^[0-9]{10}$/.test(value) || 'Mobile number must be exactly 10 digits',
                                            },
                                        })}
                                        placeholder="Enter 10-digit mobile number"
                                    />
                                    {errors.mobile && <span className={UserClasses["error-message"]}>{errors.mobile.message}</span>}


                                    <TextField
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        {...register('password', { required: 'Password is required' })}
                                        onBlur={validatePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? 'Hide' : 'Show'}
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {errors.password && <span className={UserClasses["error-message"]}>{errors.password.message}</span>}

                                    <TextField
                                        label="Confirm Password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        {...register('confirmPassword', {
                                            required: 'Confirm Password is required',
                                            validate: (value) =>
                                                value === watch('password') || 'Passwords do not match',
                                        })}
                                        onBlur={validatePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                        {showConfirmPassword ? 'Hide' : 'Show'}
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {errors.confirmPassword && <span className={UserClasses["error-message"]}>{errors.confirmPassword.message}</span>}
                                    {watch('accountType') === '4' || watch('accountType') === '6' ? null : (
                                        <>
                                            <TextField
                                                label="GSTIN Number"
                                                type="text"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                {...register('gstNumber', {
                                                    required: 'GST Number is required',
                                                    pattern: {
                                                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                                                        message: 'Invalid GST Number format',
                                                    },
                                                    validate: (value) => isValidGSTIN(value) || 'Invalid GSTIN. Please enter a valid GSTIN.',
                                                })}
                                            />
                                            {errors.gstNumber && <span className={UserClasses["error-message"]}>{errors.gstNumber.message}</span>}



                                            <TextField
                                                label="Location"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                {...register('location', { required: 'location is required' })}
                                            />
                                            {errors.location && <span className={UserClasses["error-message"]}>{errors.location.message}</span>}
                                            <TextField
                                                label="Business Name"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                {...register('businessName', { required: 'businessName is required' })}
                                            />
                                            {errors.businessName && <span className={UserClasses["error-message"]}>{errors.businessName.message}</span>}
                                        </>
                                    )}
                                    <button className={UserClasses['form-last-btn']} type="submit">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </Layout>
    );
};

export default AddUser;
