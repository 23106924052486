import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import BoardClasses from './Dashboard.module.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { Button, Card, CardHeader } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Services } from 'Services';
import { FaEllipsisV } from 'react-icons/fa';
// import newmanufacturer from '../../../Assets/Manufacturing Process (2).gif'
import newmanufacturer from '../../../Assets/g1.png'
// import newdistributor from '../../../Assets/Logistics (1).gif'
import newdistributor from '../../../Assets/g2.png'
// import retailernew from '../../../Assets/retailernew-icon.gif'
import retailernew from '../../../Assets/g3.png'
// import agentnew from '../../../Assets/agentnew-icon.gif'
import agentnew from '../../../Assets/g4.png'
// import marketingspecialist from '../../../Assets/marketingspecialistnew.gif'
import marketingspecialist from '../../../Assets/g5.png'
// import officeteamnew from '../../../Assets/officeteamnew.gif'
import officeteamnew from '../../../Assets/g6.png'
import MuiPieChart from './PieChart';
import CustomPieChart from './PieChart';
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer } from 'recharts';
import { FaUserAlt } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import framebg from '../../../Assets/Frame bg.png'
import { BarChart, Bar, CartesianGrid, } from 'recharts';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { MdPersonOff } from "react-icons/md";
import { MdPerson } from "react-icons/md";

import AOS from 'aos';
import 'aos/dist/aos.css';



const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#355BA1', padding: '5px', color: 'white', borderRadius: '5px' }}>
                <p>{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const LazyHorizontalBar = ({ data }) => {
    const [showBar, setShowBar] = useState(false);
    const containerRef = useRef(null);
    const [inViewRef, inView] = useInView();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !showBar) {
                    setShowBar(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.9, // Adjust threshold as needed
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [showBar]);

    return (
        <div ref={containerRef}>
            <div ref={inViewRef}>
                {showBar && (
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            data={data}
                            layout="vertical"
                            margin={{ top: 0, right: 30, left: 50, bottom: 0 }}
                        >
                            <defs>
                                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#5DA3C6" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#5DA3C6" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" />
                            <YAxis type="category" dataKey="name" />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar dataKey="value" stroke="#D8E8F1" fill="url(#colorGradient)" label Key="value" position="top" />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};

const LazyBar = ({ data }) => {
    const [showChart, setShowChart] = useState(false);
    const containerRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.9 // Adjust threshold as needed
        };

        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !showChart) {
                    setShowChart(true);
                    if (observerRef.current) {
                        observerRef.current.disconnect(); // Disconnect observer after triggering once
                    }
                }
            },
            options
        );

        if (containerRef.current) {
            observerRef.current.observe(containerRef.current);
        }

        return () => {
            if (observerRef.current && containerRef.current) {
                observerRef.current.unobserve(containerRef.current);
            }
        };
    }, [showChart]);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);

    return (
        <div ref={containerRef} style={{ minHeight: '400px' }}>

            {showChart && (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <defs>
                            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#5DA3C6" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#5DA3C6" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="value" fill="url(#colorGradient)" label Key="value" position="top" />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

const LazyVerticalBar = ({ data }) => {
    const [showChart, setShowChart] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !showChart) {
                    setShowChart(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1, // Adjust threshold as needed
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [showChart]);

    return (
        <div ref={containerRef}>
            {showChart && (
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <defs>
                            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#5DA3C6" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#5DA3C6" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="value" fill="url(#colorGradient)" label Key="value" position="top" />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

const LazyUserStatisticsChart = ({ data }) => {
    const [showChart, setShowChart] = useState(false);
    const containerRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.9 // Adjust threshold as needed
        };

        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !showChart) {
                    setShowChart(true);
                    if (observerRef.current) {
                        observerRef.current.disconnect(); // Disconnect observer after triggering once
                    }
                }
            },
            options
        );

        if (containerRef.current) {
            observerRef.current.observe(containerRef.current);
        }

        return () => {
            if (observerRef.current && containerRef.current) {
                observerRef.current.unobserve(containerRef.current);
            }
        };
    }, [showChart]);

    return (
        <div ref={containerRef} style={{ minHeight: '400px' }}>
            {showChart && (
                <ResponsiveContainer height={400} style={{ overflowY: 'hidden' }}>
                    <AreaChart data={data}>
                        <defs>
                            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#5DA3C6" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#5DA3C6" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey="value" stroke="#5DA3C6" fill="url(#colorGradient)" label Key="value" position="top" />
                    </AreaChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

// Function to get the current day of the year
const getCurrentDayOfYear = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
};

// Function to get the current day of the month
const getCurrentDayOfMonth = () => {
    const now = new Date();
    return now.getDate();
};

const Dashboard = () => {
    const [FromDate, setFromDate] = useState(null);
    const [ToDate, setToDate] = useState(null);
    const [totalManufacturer, setTotalManufacturers] = useState(0);
    const [totalDistributors, setTotalDistributors] = useState(0);
    const [totalFreetrailUsers, setTotalFreeTrailUsers] = useState(0);
    const [totatRetailers, setTotalRetailers] = useState(0);
    const [totalAgents, setTotalAgents] = useState(0);
    const [totalmarketingSpecialist, setTotalMarketingSpecialist] = useState(0);
    const [totalPaidUsers, setTotalPaidUsers] = useState(0);
    const [totalPendingTickets, setTotalPendingTickets] = useState(0);
    const [totalOfficeTeam, setTotalOfficeTeam] = useState(0);
    const [totalusers, setTotalUsers] = useState(0);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('MONTH'); // Default option is 'MONTH'
    const [totalPendingBills, setTotalPendingBills] = useState(0);
    const [totalTodayOrders, setTotalTodayOrders] = useState(0);
    const [totalgeneratedBills, setTotalGeneratedBills] = useState(0);
    const [allBills, setAllBills] = useState(0);
    const [totalAprrovedOrders, setTotalApprovedOrders] = useState(0);
    const [totalrejectedOrders, setTotalRejectedOrders] = useState(0);
    const [totalNewUsers, setTotalNewUserRegistrations] = useState(0);
    const [totalpendingPayments, setTotalPendingPayments] = useState(0);
    const [count, setCount] = useState(1); // Initial count value
    const [intervalId, setIntervalId] = useState(null); // Interval ID to clear the interval
    const [cancelledAccounts, SetCancelledAccounts] = useState(0);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setDropdownOpen(false);

        fetchData(option);
    };

    useEffect(() => {
        fetchData(selectedOption);
    }, [selectedOption]);

    const fetchData = (type) => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        Services.getDashboardData(type, null, null, token)
            .then((response) => {
                setTotalManufacturers(response.totalManufacturer);
                setTotalDistributors(response.totalDistributors);
                setTotalFreeTrailUsers(response.totalFreetrailUsers)
                setTotalRetailers(response.totatRetailers)
                setTotalAgents(response.totalAgents)
                setTotalMarketingSpecialist(response.totalmarketingSpecialist)
                setTotalPaidUsers(response.totalPaidUsers)
                setTotalPendingTickets(response.totalPendingTickets)
                setTotalOfficeTeam(response.totalOfficeTeam)
                setTotalUsers(response.totalusers)
                setTotalPendingBills(response.totalPendingBills)
                setTotalTodayOrders(response.totalTodayOrders)
                setTotalGeneratedBills(response.totalgeneratedBills)
                setTotalApprovedOrders(response.totalAprrovedOrders)
                setTotalRejectedOrders(response.totalrejectedOrders)
                setAllBills(response.allBills)
                setTotalNewUserRegistrations(response.totalNewUsers)
                setTotalPendingPayments(response.totalpendingPayments)
                SetCancelledAccounts(response.cancelledAccounts)
            })
            .catch((error) => {
                console.error('Error fetching dashboard data:', error);
            });
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const handleSubmit = () => {
        if (FromDate && ToDate) {
            const formattedFromDate = formatDate(FromDate);
            const formattedToDate = formatDate(ToDate);
            const type = 'CUSTOM';
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };

            Services.getDashboardData(type, formattedFromDate, formattedToDate, token)
                .then((response) => {
                    setTotalManufacturers(response.totalManufacturer);
                    setTotalDistributors(response.totalDistributors);
                    setTotalFreeTrailUsers(response.totalFreetrailUsers)
                    setTotalRetailers(response.totatRetailers)
                    setTotalAgents(response.totalAgents)
                    setTotalMarketingSpecialist(response.totalmarketingSpecialist)
                    setTotalPaidUsers(response.totalPaidUsers)
                    setTotalPendingTickets(response.totalPendingTickets)
                    setTotalOfficeTeam(response.totalOfficeTeam)
                    setTotalUsers(response.totalusers)
                    setTotalPendingBills(response.totalPendingBills)
                    setTotalTodayOrders(response.totalTodayOrders)
                    setTotalGeneratedBills(response.totalgeneratedBills)
                    setTotalApprovedOrders(response.totalAprrovedOrders)
                    setTotalRejectedOrders(response.totalrejectedOrders)
                    setAllBills(response.allBills)
                    setTotalNewUserRegistrations(response.totalNewUsers)
                    setTotalPendingPayments(response.totalpendingPayments)
                    SetCancelledAccounts(response.cancelledAccounts)
                    console.log(response);
                })
                .catch((error) => {
                    console.error('Error fetching dashboard data:', error);
                });
        }
    };

    const [pendingBills, setPendingBills] = useState([]);
    const [overdueBills, setOverdueBills] = useState([]);

    useEffect(() => {
        const fetchPendingBills = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getPendingBills(token);
                setPendingBills(response.pendingBills); // Assuming the API response is an array of pending bills
            } catch (error) {
                console.error('Error fetching pending bills:', error);
            }
        };

        const fetchOverdueBills = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getCancelledSubscriptionBills(token);
                setOverdueBills(response.subscriptionCancelledbills); // Assuming the API response is an array of overdue bills
            } catch (error) {
                console.error('Error fetching overdue bills:', error);
            }
        };

        fetchPendingBills();
        fetchOverdueBills();
    }, []);

    const data = [
        { name: 'Manufacturers', value: totalManufacturer },
        { name: 'Distributors', value: totalDistributors },
        { name: 'Retailers', value: totatRetailers },
        { name: 'Agents', value: totalAgents },
        { name: 'Marketing Specialists', value: totalmarketingSpecialist },
        { name: 'Office Team', value: totalOfficeTeam }
    ];

    const horizontalData = [
        { name: 'Pending Bills', value: totalPendingBills },
        { name: 'Paid Bills', value: totalgeneratedBills },
        { name: 'All Bills', value: allBills },
    ];

    const userStatisticsData = [
        { name: 'Free Trial Users', value: totalFreetrailUsers },
        { name: 'Paid Users', value: totalPaidUsers },
        { name: 'Total Users', value: totalusers },
    ];

    const verticalData = [
        { name: 'Approved Orders', value: totalAprrovedOrders },
        { name: 'Rejected Orders', value: totalrejectedOrders },
        { name: 'Today Orders', value: totalTodayOrders },
    ]

    useEffect(() => {
        const id = setInterval(() => {
            setCount((prevCount) => prevCount + 1); // Decrement the count by 1
        }, 1); // Update count every second
        setIntervalId(id); // Save interval ID
        return () => clearInterval(id); // Cleanup on unmount
    }, []); // Run only once when component mounts

    // Clear the interval when count reaches 0
    useEffect(() => {
        if (count === 200) {
            clearInterval(intervalId);
        }
    }, [count, intervalId]);

    // Disable future dates starting from today
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison

    const navigate = useNavigate('');

    const handleTableRowClick = (userId) => {
        navigate(`/each-user/${userId}`);
    };

    return (
        <Layout Active={'Dashboard'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={BoardClasses['board-users-main']}>
                        <div className={BoardClasses['dashboard-main']}>
                            <div className={BoardClasses['dates-select-main-one']}>
                                <div className={BoardClasses['date-board-main']}>
                                    <p className={BoardClasses['date-icon-board']}><FaCalendarAlt /></p>
                                    <DatePicker
                                        selected={FromDate}
                                        onChange={handleFromDateChange}
                                        placeholderText="Select From Date"
                                        className={BoardClasses['Inp-date-picker']}
                                        maxDate={today} // Disable future dates
                                    />
                                </div>
                                <div className={BoardClasses['date-board-main']}>
                                    <p className={BoardClasses['date-icon-board']}><FaCalendarAlt /></p>
                                    <DatePicker
                                        selected={ToDate}
                                        onChange={handleToDateChange}
                                        placeholderText="Select To Date"
                                        className={BoardClasses['Inp-date-picker']}
                                        maxDate={today} // Disable future dates
                                    />
                                </div>
                                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                            <div className={BoardClasses['dates-select-main-one']}>
                                <div className={BoardClasses['dropdown-board-main']}>
                                    <select value={selectedOption} onChange={(e) => handleOptionClick(e.target.value)}>
                                        <option value="TODAY">This Day</option>
                                        <option value="WEEK">This Week</option>
                                        <option value="MONTH">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={BoardClasses['users-secondary-main']}>
                            <div className={BoardClasses['sec-card-one']} data-aos="zoom-out-right">
                                <div className={BoardClasses['inside-sec-one-card']}>
                                    <h3><MdPerson /></h3>
                                </div>
                                <div className={BoardClasses['inside-sec-one-two-card']}>
                                    <p className={BoardClasses['text-sec-card']}>New User Registrations</p>
                                    <p className={BoardClasses['registration-text']}><CountUp end={totalNewUsers} duration={2} /></p>
                                </div>
                            </div>
                            <div className={BoardClasses['sec-card-three']} data-aos="zoom-out-down">
                                <div className={BoardClasses['inside-sec-one-card']}>
                                    <h3><VscFeedback /></h3>
                                </div>
                                <div className={BoardClasses['inside-sec-one-two-card']}>
                                    <p className={BoardClasses['text-sec-card']}>Pending Complaints</p>
                                    <p className={BoardClasses['pendingtickets-text']}><CountUp end={totalPendingTickets} duration={2} /></p>
                                </div>
                            </div>
                            <div className={BoardClasses['sec-card-three']} data-aos="zoom-out-left">
                                <div className={BoardClasses['inside-sec-one-card']}>
                                    <h3><MdPersonOff /></h3>
                                </div>
                                <div className={BoardClasses['inside-sec-one-two-card']}>
                                    <p className={BoardClasses['text-sec-card']}>Deleted Accounts</p>
                                    <p className={BoardClasses['pendingtickets-text']}><CountUp end={cancelledAccounts} duration={2} /></p>
                                </div>
                            </div>
                        </div>

                        <div className={BoardClasses['second-board-main']}>
                            <div className={BoardClasses['large-card']}>
                                <h1 className={BoardClasses['bill-stats-data']}>BILL RECORDS</h1>
                                <LazyHorizontalBar data={horizontalData} />
                            </div>

                            <div className={BoardClasses['large-card']}>
                                <h1 className={BoardClasses['bill-stats-data']}>ORDER STATISTICS</h1>
                                <LazyVerticalBar data={verticalData} />
                            </div>
                        </div>
                        <div className={BoardClasses['main-board-cards']}>
                            <div className={BoardClasses['graphs-heading']}>
                                <h1>USER STATISTICS</h1>
                            </div>
                            <LazyUserStatisticsChart data={userStatisticsData} style={{ overflowY: 'hidden' }} />
                        </div>
                        
                        <div className={BoardClasses['large-card']}>
                            <h1 className={BoardClasses['bill-stats-data']}>PENDING BILLS</h1>
                            <div className={BoardClasses['table-container']} data-aos="fade-up"
                            data-aos-anchor-placement="bottom-bottom">
                                {pendingBills.length === 0 ? (
                                    <p className={BoardClasses['dashrec-notfound']}>No records found</p>
                                ) : (
                                    <table className={BoardClasses['bill-table']}>
                                        <thead>
                                            <tr>
                                                <th>Customer Name</th>
                                                <th>Amount</th>
                                                <th>Due Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pendingBills.map((bill) => (
                                                <tr key={bill.id} onClick={() => handleTableRowClick(bill.userId)}>
                                                    <td>{bill.name}</td>
                                                    <td>{Math.round(bill.totalAmount)}</td>
                                                    <td>{formatDate(bill.expiredDate)}</td>
                                                    <td>N/A</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>



                        <div className={BoardClasses['main-board-cards']}>
                            <div className={BoardClasses['graphs-heading']}>
                                <h1>USERS</h1>
                            </div>
                            <LazyBar data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
