import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import TeamClasses from '../account/AdminSettings.module.css';
import { Helmet } from 'react-helmet';
import { FaLock } from 'react-icons/fa';
import { Services } from 'Services';
import { Grid, TextField, Typography } from '@mui/material';
import Shipsy from '../../../Assets/SHIPSY_LOGO_BIRD_BLUE.png';
import { useNavigate } from 'react-router-dom';
import { Success } from 'Util/Toast';
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';
import AOS from 'aos';
import 'aos/dist/aos.css';


const AdminSettings = () => {
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showAdminFormTwo, setShowAdminFormTwo] = useState(true);
    const [showUsersSubTwo, setShowUsersSubTwo] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
        setShowAdminFormTwo(false);
        setShowUsersSubTwo(false);
    };

    const closeModal = () => {
        setShowModal(false);
        setShowAdminFormTwo(true);
        setShowUsersSubTwo(true);
        setSuccessMessage('');
        setErrorMessage('');
    };

    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!oldPassword.trim()) {
            setOldPasswordError('Old Password cannot be empty');
            return;
        }

        if (!newPassword.trim()) {
            setNewPasswordError('New Password cannot be empty');
            return;
        }

        if (!confirmPassword.trim()) {
            setConfirmPasswordError('Confirm Password cannot be empty');
            return;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }

        try {
            const response = await Services.updateAdminPassword({
                password: oldPassword,
                newPassword: newPassword,
            }, { Authorization: `token ${localStorage.getItem('Token')}` });

            if (response.Status === 1) {
                setShowModal(false);
                Success(response.Message);
                setSuccessMessage('Password updated successfully.');
            } else {
                setErrorMessage('Error updating password: ' + response.Message);
            }
        } catch (error) {
            console.error('Error updating password:', error);
            setErrorMessage('An unexpected error occurred.');
        }
    };

    useEffect(() => {
        if (!showModal) {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setSuccessMessage('');
            setErrorMessage('');
        }
    }, [showModal]);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);

    return (
        <Layout Active={'AdminSettings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin Settings</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={UserClasses['users-sub-one']}>
                        {showUsersSubTwo && (
                            <div className={UserClasses['users-sub-two']}>
                                <div className={UserClasses['users-div']}>
                                    <h4 className={UserClasses['users-heading-one']}>Admin Settings</h4>
                                </div>
                                {AccessControl(AccessKeys.AdminSettings)?.edit === 1 && (
                                    <div className={UserClasses['change-pass-div']}>
                                        <button className={TeamClasses['change-password-btn']} onClick={openModal}>
                                            Change account password ??
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {!showAdminFormTwo && (
                            <div className={TeamClasses['admin-form-two']}>
                                <button className={TeamClasses['admin-arrow-btn']} onClick={closeModal}><h1 className={TeamClasses['admin-arrowback']}><IoArrowBackCircleSharp /></h1></button>
                                <div className={TeamClasses['mainDiv']}>
                                    <div className={TeamClasses['cardStyle']}>
                                        <form onSubmit={handleFormSubmit} id="adminSettingsForm">
                                            <img src={Shipsy} className={TeamClasses['signupLogo']} data-aos="fade-down-right" />
                                            <h2 className={TeamClasses['formTitle']}>Change Password</h2>

                                            <div className={TeamClasses['inputDiv']}>
                                                <label className={TeamClasses['inputLabel']} htmlFor="oldPassword">Old Password</label>
                                                <input
                                                    type="password"
                                                    id="oldPassword"
                                                    name="oldPassword"
                                                    value={oldPassword}
                                                    onChange={(e) => {
                                                        setOldPassword(e.target.value);
                                                        setOldPasswordError('');
                                                    }}
                                                />
                                                {oldPasswordError && <div className={TeamClasses['errorText']}>{oldPasswordError}</div>}
                                            </div>

                                            <div className={TeamClasses['inputDiv']}>
                                                <label className={TeamClasses['inputLabel']} htmlFor="newPassword">New Password</label>
                                                <input
                                                    type="password"
                                                    id="newPassword"
                                                    name="newPassword"
                                                    value={newPassword}
                                                    onChange={(e) => {
                                                        setNewPassword(e.target.value);
                                                        setNewPasswordError('');
                                                    }}
                                                />
                                                {newPasswordError && <div className={TeamClasses['errorText']}>{newPasswordError}</div>}
                                            </div>

                                            <div className={TeamClasses['inputDiv']}>
                                                <label className={TeamClasses['inputLabel']} htmlFor="confirmPassword">Confirm Password</label>
                                                <input
                                                    type="password"
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => {
                                                        setConfirmPassword(e.target.value);
                                                        setConfirmPasswordError('');
                                                    }}
                                                />
                                                {confirmPasswordError && <div className={TeamClasses['errorText']}>{confirmPasswordError}</div>}
                                            </div>

                                            <div className={TeamClasses['buttonWrapper']}>
                                                <button type="submit" id="submitButton" className={TeamClasses['submitButton']}>
                                                    <span>Continue</span>
                                                    <span className={TeamClasses['loader']}></span>
                                                </button>
                                            </div>

                                            {successMessage && <div className={TeamClasses['successMessage']}>{successMessage}</div>}
                                            {errorMessage && <div className={TeamClasses['errorMessage']}>{errorMessage}</div>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AdminSettings;
