// import { menuItems } from "../../Components/SideNav/config";
import { menuItems } from "Components/SideNav/config";



let Permissions = []

menuItems.map(item => {

 if (item.multiple) {

  item.items.map((data, key) => {
   Permissions.push({ name: data.name, Permissions: { read: 1, write: 1, edit: 0 } })
  })

 }
 else {
  Permissions.push({ name: item.name, Permissions: { read: 0, write: 0, edit: 0 } })

 }
})

export default Permissions