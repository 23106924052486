
import uuid from "react-uuid";

import { AiOutlineDashboard } from "react-icons/ai";
import { FaPeopleGroup } from "react-icons/fa6";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { BiSolidDonateHeart } from "react-icons/bi";
import { IoSettingsSharp } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { FcDataConfiguration } from "react-icons/fc";
import { MdCategory } from "react-icons/md";
import UsersReferenceGuide from "pages/Admin/userreference/UsersReferenceGuide";
import { VscReferences } from "react-icons/vsc";


export const permissions = {
   Users: { read: ["view-Users"] },
   Orders: { read: ['view-Orders'] },
   SubscriptionPlans: { read: ["view-SubscriptionPlans"] },
   AccountSettings: { read: ["view-AccountSettings"] },
   AdminSettings: { read: ["view-AdminSettings"] },
   Complaints: { read: ["view-Complaints"] },
   UsersReferenceGuide: { read: ["view-UsersReferenceGuide"]},
}


export const menuItems = [

 {
      id: uuid(),
      multiple: false,
      name: "Dashboard",
      menuLink: "/dashboard",
      active: "Dashboard",
      icon: AiOutlineDashboard,    
 }, 

 {
  id: uuid(),
  multiple: false,
  name: "Users",
  menuLink: "/account-settings?page=1&activeTab=1",
  active: "Account Settings",
  icon: FaPeopleGroup,
  Permissions: permissions.Users.read,

 },

 {
    id: uuid(),
    multiple: false,
    name: "Orders",
    menuLink: "/orders?page=1&activeTab=1",
    active: "Orders",
    icon: GiCardboardBoxClosed,
    Permissions: permissions.Orders.read,
 },

 {
   id: uuid(),
   multiple: true,
   name: "Product",
   menuLink: "/product",
   active: "Product",
   icon: MdCategory,
   items: [
      {
         id:'1',
         name: "Product Category",
         menuLink: "/productcategory",
         active: "ProductCategory",
         icon: BiSolidDonateHeart,
      },
      {
         id:'2',
         name: "Product SubCategory",
         menuLink: "/productsubcategory",
         active: "ProductSubCategory",
         icon: BiSolidDonateHeart,
      },
      {
         id:'3',
         name: "Product Price",
         menuLink: "/productprice",
         active: "ProductPrice",
         icon: BiSolidDonateHeart,
         Permissions: permissions.AdminSettings.read,
      },
   ]
},

 {
   id: uuid(),
   multiple: false,
   name: "Subscription Plans",
   menuLink: "/subscriptionplans",
   active: "SubscriptionPlans",
   icon: BiSolidDonateHeart,
   Permissions: permissions.SubscriptionPlans.read,
},

{
   id: uuid(),
   multiple: false,
   name: "User Reference Guide",
   menuLink: "/usersreferenceguide",
   active: "UsersReferenceGuide",
   icon: VscReferences,
   Permissions: permissions.SubscriptionPlans.read,
},

{
   id: uuid(),
   multiple: true,
   name: "Account Settings",
   menuLink: "/accountsettings",
   active: "AccountSettings",
   icon: IoSettingsSharp,
   items: [
      {
         id:'1',
         name: "Admin Settings",
         menuLink: "/adminsettings",
         active: "AdminSettings",
         icon: BiSolidDonateHeart,
         Permissions: permissions.AdminSettings.read,
      },
      {
         id:'2',
         name: "Permissions",
         menuLink: "/permissions",
         active: "Permissions",
         icon: BiSolidDonateHeart,
      }
   ]
},

{
   id: uuid(),
   multiple: true,
   name: "Support",
   menuLink: "/support",
   active: "Support",
   icon: RiCustomerService2Line,
   items: [
      {
         id:'1',
         name: "Complaints",
         menuLink: "/complaints",
         active: "Complaints",
         icon: BiSolidDonateHeart,
         Permissions: permissions.Complaints.read,
      }
   ]
},

{
   id: uuid(),
   multiple: false,
   name: "Configuration",
   menuLink: "/configuration",
   active: "Configuration",
   icon: FcDataConfiguration,
   // Permissions: permissions.Configuration.read,
},
];

